import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './safetyRing.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'



function SafetyRing(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        margin: "0 auto",
        padding: "60px 160px",
    
    };


    return(
        <div style={{width:'100%'}}>
            <Header current="企业安环" active={4}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                </div>
                <div className='foodSafety-box1'>
                    <div className='foodSafety-box1-font2'>企业安环</div>
                    <div className='foodSafety-box1-font3'>Enterprise security</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p2} style={{height:80,width:82}}/>
                        <div className='foodSafety-box2-font1'>九小场所</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p3} style={{height:80,width:76}}/>
                        <div className='foodSafety-box2-font1'>巡查巡检</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p4} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>自查自检</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p5} style={{height:80,width:82}}/>
                        <div className='foodSafety-box2-font1'>安全培训</div>
                    </div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}><div className='drugSafety-font1' style={{lineHeight:2}}>以“安全生产，环境保护”为目标，按照“安全监管、安防监控、监测预警三位一体”的建设思路建立健全安监信息化工作评估标准和考核机制。建立企业数据管理，建立市场监管风险预警把控，提升电子化台账效率，强化社会共治共管，普及AI智能分析预警服务</div>
                </div>
                <div className='foodSafety-box2' style={{height:350}}>
                    <img src={p6} style={{height:428,width:600}}/>
                </div>
                <div className='foodSafety-box1' style={{marginTop:100,marginBottom:60}}>
                    <div className='foodSafety-box1-font2'>产品中心</div>
                    <div className='foodSafety-box1-font3'>Product Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:1000,marginLeft:-50}}>
                        <div className='foodSafety-box1-font2'>九小场所</div>
                        <div className='foodSafety-box1-font3'>Nine small places</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>结合数据地图建立对九小场所企业信用信息的智能管理及普查。为一线监管工作提供有效的九小场所信息/许可数据/信用信息关联拓扑的数据抓手。</div>
                    </div>
                    <img src={p7} className='safetyRing-box3-img1' style={{height:364,width:464}}/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p8} className='safetyRing-box3-img2' style={{height:364,width:504}}/>
                    <div className='foodSafety-box3' style={{paddingLeft:150,width:900}}>
                        <div className='foodSafety-box1-font2'>巡查巡检系统</div>
                        <div className='foodSafety-box1-font3'>Inspection inspection system</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>日常巡检，关于安全、环保的问题进行违规报送，监督企业限时整改，追踪整改进度。</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:990,marginLeft:-50}}>
                        <div className='foodSafety-box1-font2'>自查自检系统</div>
                        <div className='foodSafety-box1-font3'>Self-check and self-test system</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>对企业自查情况进行管理，对风险信息、风险措施信息、隐患信息、隐患整改信息进行维护管理，支持及时自查，随时查看，针对长期未报送台账的企业，并做出对策。</div>
                    </div>
                    <img src={p9} className='safetyRing-box3-img3' style={{height:372,width:488}}/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p10} className='safetyRing-box3-img2' style={{height:364,width:464}}/>
                    <div className='foodSafety-box3' style={{paddingLeft:200,width:950}}>
                        <div className='foodSafety-box1-font2'>安全培训系统</div>
                        <div className='foodSafety-box1-font3'>Safety training system</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>通过该系统，可实现对所有企业进行远程法律法规、国家政策、安全知识、警示案例等培训学习，加强企业安全生产的意识。定时进行考核，考察当前的学习情况。使从业人员做到及时学习，认真考试。</div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SafetyRing 