import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './drugSafety.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'
import p11 from './imgs/p11.png'
import p12 from './imgs/p12.png'


function DrugSafety(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition:"center",

    };

    

    return(
        <div style={{width:'100%'}}>
            <Header current="智慧药安监管服务" active={1}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                </div>
                <div className='foodSafety-box1'>
                    <div className='foodSafety-box1-font2'>智慧药安监管服务</div>
                    <div className='foodSafety-box1-font3'>Smart drug safety supervision services</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}>
                    <div className='drugSafety-box2-img1'>
                        <img src={p2} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>药品</div>
                    </div>
                    <div className='drugSafety-box2-img1'>
                        <img src={p3} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>化妆品</div>
                    </div>
                    <div className='drugSafety-box2-img1'>
                        <img src={p4} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>医疗器械</div>
                    </div>
                    <div className='drugSafety-box2-img1'>
                        <img src={p5} style={{height:80,width:70}}/>
                        <div className='foodSafety-box2-font1'>监管台账</div>
                    </div>
                    <div className='drugSafety-box2-img1'>
                        <img src={p6} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>药物警戒</div>
                    </div>
                    <div className='drugSafety-box2-img1'>
                        <img src={p7} style={{height:80,width:70}}/>
                        <div className='foodSafety-box2-font1'>数字可视</div>
                    </div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}><div className='drugSafety-font1'>药品监管台账动态可视，涵盖：药品使用单位、零售药店、医疗器械经营、化妆品经营、化妆品使用等单位的数据台账多元化管理；药物警戒智能预防，对临期过期、购进异常、追溯异常建立智能化识别预防；药品监管信息数字可视，完善对药品、医疗器械、化妆品的数字化动态监管体制</div>
                </div>
                <div className='foodSafety-box2' style={{height:350}}>
                    <img src={p8} style={{height:750,width:1100,marginLeft:50}}/>
                </div>
                <div className='foodSafety-box1' style={{marginTop:180,marginBottom:60}}>
                    <div className='foodSafety-box1-font2'>产品中心</div>
                    <div className='foodSafety-box1-font3'>Product Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:800,marginLeft:210}}>
                        <div className='foodSafety-box1-font2'>医疗机构网格可视化管理</div>
                        <div className='foodSafety-box1-font3'>Grid visual management of medical institutions</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>落实网格化责任制管理，建立辖区医疗机构的动态数据展示，实现对药品的药物警戒、监管台账、监管信息动态掌控，有效预防及分析药品单位的风险及安全隐患，从发现、告知、检查、落实全链条闭环。</div>
                    </div>
                    <img src={p9} className='drugSafety-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p10} className='foodSafety-box3-img2'/>
                    <div className='foodSafety-box3' style={{paddingLeft:50,width:800}}>
                        <div className='foodSafety-box1-font2'>药品数字化监管</div>
                        <div className='foodSafety-box1-font3'>Digital regulation of pharmaceuticals</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>实现对医疗机构、社区诊所建立药品规范化核销管理平台，实现对医疗机构的中西成药、毒麻精放、中药饮片的药品进货查验、出货核销建立远程数字化监管，构筑药品安全监管对医疗机构的闭环监测。</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:770}}>
                        <div className='foodSafety-box1-font2'>医疗器械数字化监管</div>
                        <div className='foodSafety-box1-font3'>Digital regulation of medical devices</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>基于医疗机构、社区诊所建立医疗器械规范化核销管理平台，实现对医疗机构各类型的医疗器械进货状态建立动态监测，完善药品安全监管对医疗机构购进的医疗器械建立动态台账管理。</div>
                    </div>
                    <img src={p11} className='drugSafety-box3-img3'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p12} className='drugSafety-box3-img2'/>
                    <div className='foodSafety-box3' style={{paddingLeft:150,width:900}}>
                        <div className='foodSafety-box1-font2'>化妆品数字化监管</div>
                        <div className='foodSafety-box1-font3'>Digital regulation of cosmetics</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>提升化妆品进货台账的数字化监管，实现电子化企业自查、票据规范整理、一店一码公示等应用，助力市场监管对化妆品行业的规范提升。</div>
                    </div>
                </div>
                
            </div>
        </div>
    )

}

export default DrugSafety 