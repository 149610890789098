import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './smartAudit.css'
import p1 from './imgs/p1.mp4'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import {baseUrl} from '../../../api/commonSrc'



function SmartAudit(props){

    // const borderBg1 = {
    //     width:"100%",
    //     height:'30rem',
    //     backgroundImage: 'url(' +p1 + ')',
    //     backgroundRepeat:"no-repeat",
    //     backgroundSize: "100% 100%",
    //     backgroundPosition:"center",
    //     margin: "0 auto",
    //     padding: "60px 160px",
    
    // };


    return(
        <div style={{width:'100%'}}>
            <Header current="智慧审计" active={3}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div className="smartAudit-video-box">
                    <video className="smartAudit-video-background" src={p1} muted="muted" autoplay="autoplay" loop></video>
                    <div className='smartAudit-video-font1'>让审计更规范，让定性更标准</div>
                    <div className='smartAudit-video-font2'>以助手型发挥审计资料信息化的</div>
                    <div className='smartAudit-video-font3'>互联、互通、共用、共享</div>
                </div>
                <div className='foodSafety-box2' style={{height:800,marginTop:-50}}>
                    <div className='foodSafety-box3' style={{width:700}}>
                        <div className='foodSafety-box1-font2'>智慧审计文献服务平台</div>
                        <div className='foodSafety-box1-font3'>Smart audit literature service platform</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>为审前方案建立职责政策“ 搜索帮手”; 为审中过程建立法规文献“查询助手”；为审计报告建立定性依据 “检索抓手”</div>
                        <div className='campusSecurity-box-left'>
                            <div className='riskHandling-box2-img1'>
                                <img src={p3} style={{height:72,width:75}}/>
                                <div className='campusSecurity-box2-font1'>审前方案聚焦</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p4} style={{height:41,width:75,marginTop:15,marginBottom:14}}/>
                                <div className='campusSecurity-box2-font1'>审中过程聚焦</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p5} style={{height:62,width:72,marginTop:5,marginBottom:4}}/>
                                <div className='campusSecurity-box2-font1'>审计报告聚焦</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/auditLiterature')}/>
                        </div>
                    </div>
                    <img src={p2} className='smartAudit-box3-img1'/>
                </div>
                
            </div>
        </div>
    )

}

export default SmartAudit 