import { Divider } from 'antd'
import React,{ useState, useEffect} from 'react'
import './footer.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import code1 from './imgs/code.jpg' 
import code2 from '../../page/ecoChain/imgs/code1.png'
import {baseUrl,phone1,phone2,companyName,companyName1, address} from '../../api/commonSrc'

function Footer(props){

    const onSubmitClick = (name) => {
        if(name === '首页'){
            window.location.replace(baseUrl + '/#/home')
        }else if(name === '智慧市场'){
            window.location.replace(baseUrl + '/#/smartMarket')
        }else if(name === '校园安全'){
            window.location.replace(baseUrl + '/#/campusSecurity')
        }else if(name === '智慧审计'){
            window.location.replace(baseUrl + '/#/smartAudit')
        }else if(name === '智慧乡村'){
            window.location.replace(baseUrl + '/#/smartVillage')
        }else if(name === '关于我们'){
            window.location.replace(baseUrl + '/#/aboutUs')
        }else if(name === '互联网+明厨亮灶'){
            window.location.replace(baseUrl + '/#/foodSafety')
        }else if (name === '智慧药安监管服务'){
            window.location.replace(baseUrl + '/#/drugSafety')
        }else if (name === '信用信息共享服务'){
            window.location.replace(baseUrl + '/#/informationSharing')
        }else if (name === '风险事件办理中心'){
            window.location.replace(baseUrl + '/#/riskHandling')
        }else if (name === '政务服务'){
            window.location.replace(baseUrl + '/#/convenience')
        }else if (name === '数字三农'){
            window.location.replace(baseUrl + '/#/digitalFarming')
        }else if (name === '企业安环'){
            window.location.replace(baseUrl + '/#/safetyRing')
        }else if (name === '乡村生态'){
            window.location.replace(baseUrl + '/#/ruralEcology')
        }else if (name === '智慧食安·透明工厂'){
            window.location.replace(baseUrl + '/#/transparentPlant')
        }else if (name === '特种设备一码多用监管'){
            window.location.replace(baseUrl + '/#/specialEquipment')
        }else if (name === '协同办公流转助手'){
            window.location.replace(baseUrl + '/#/coordinateOfficeFlow')
        }else if (name === '案件案号内部协同平台'){
            window.location.replace(baseUrl + '/#/caseNumber')
        }else if (name === '校园食安卫士'){
            window.location.replace(baseUrl + '/#/foodSafetyGuard')
        }else if (name === '智慧审计文献服务平台'){
            window.location.replace(baseUrl + '/#/auditLiterature')
        }else if (name === '党建服务'){
            window.location.replace(baseUrl + '/#/partyBuilding')
        }else if (name === '基层治理'){
            window.location.replace(baseUrl + '/#/grassroots')
        }else if (name === '产业融合'){
            window.location.replace(baseUrl + '/#/industryAmalgamation')
        }else if (name === '民声民情'){
            window.location.replace(baseUrl + '/#/peopleslivelihood')
        }
    }

    const openHref = () =>{
        window.open('https://beian.miit.gov.cn/')
    }


    return (
        <div style={{height:'100%',width:'100%'}} className='footer-box'>
            <div className='footer-box-top'>
                <div className='footer-ecochain-box'>
                    <div className='ecochain-box-left'>
                        <img src={code1} style={{width:'7vw',height:'7vw'}}/>
                        <div className='ecochain-box-left-line'></div>
                        <div className='ecochain-box-left-font1'>桥联云助手</div>
                    </div>
                    <div className='ecochain-box-left'>
                        <img src={code2} style={{width:'7vw',height:'7vw'}}/>
                        <div className='ecochain-box-left-line'></div>
                        <div className='ecochain-box-left-font1'>桥联生态链</div>
                    </div>
                </div>
                <div className='box-top1' style={{marginLeft:'2vw'}}>
                    <div className='box-top1-font1' onClick={()=>onSubmitClick("智慧市场")}>智慧市场</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("互联网+明厨亮灶")}>互联网+明厨亮灶</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("智慧食安·透明工厂")}>智慧食安·透明工厂</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("智慧药安监管服务")}>智慧药安监管服务</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("特种设备一码多用监管")}>特种设备一码多用监管</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("信用信息共享服务")}>信用信息共享服务</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("协同办公流转助手")}>协同办公流转助手</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("风险事件办理中心")}>风险事件办理中心</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("案件案号内部协同平台")}>案件案号内部协同平台</div>
                </div>
                <div className='box-top1'>
                    <div className='box-top1-font1' onClick={()=>onSubmitClick("校园安全")}>校园安全</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("校园食安卫士")}>校园食安卫士</div>
                </div>
                <div className='box-top1'>
                    <div className='box-top1-font1' onClick={()=>onSubmitClick("智慧审计")}>智慧审计</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("智慧审计文献服务平台")}>智慧审计文献服务平台</div>
                </div>
                <div className='box-top1'>
                    <div className='box-top1-font1' onClick={()=>onSubmitClick("智慧乡村")}>智慧乡村</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("党建服务")}>党建服务</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("政务服务")}>政务服务</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("基层治理")}>基层治理</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("数字三农")}>数字三农</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("产业融合")}>产业融合</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("企业安环")}>企业安环</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("民声民情")}>民声民情</div>
                    <div className='box-top1-font2' onClick={()=>onSubmitClick("乡村生态")}>乡村生态</div>
                </div>
                <div className='box-top1' style={{width:'30%'}}>
                    <div className='box-top1-font1' onClick={()=>onSubmitClick("联系我们")}>联系我们</div>
                    <div className='box-top1-font1'>{companyName}</div>
                    <div className='box-top1-font3' style={{marginTop: 30}}>
                        <img src={p1} style={{height:'2.2vw',width:'2.2vw'}}/>
                        <div style={{height:'100%'}} className='top1-font4'>
                            {
                                companyName === '中国联合网络通信集团有限公司' ? <div>技术支持热线</div> : <div>联系电话</div>
                            }
                            <div>{phone1}</div>
                        </div>
                    </div>
                    {
                         companyName === '青岛欧嘉悦网络科技有限公司' ? null :  companyName === '青岛因特信息科技有限责任公司' ? null : companyName === '青岛中微世纪信息科技有限公司' ? null :
                         <div className='box-top1-font3'>
                            <img src={p1} style={{height:'2.2vw',width:'2.2vw'}}/>
                            <div style={{height:'100%'}} className='top1-font4'>
                                {
                                    companyName === '中国联合网络通信集团有限公司' ? <div>技术支持热线</div> : <div>联系电话</div>
                                }
                                <div>{phone2}</div>
                            </div>
                        </div>
                    }
                    
                    <div className='box-top1-font3'>
                        <img src={p2} style={{height:'2.2vw',width:'2.2vw'}}/>
                        <div style={{height:'100%'}} className='top1-font4'>
                            <div>地址</div>
                            <div>{address}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='divider-box'/>
            <div className='footer-bottom' style={{display:'flex'}}>©2022{companyName} &nbsp;&nbsp;版权所有 &nbsp;
            {
                 companyName === '青岛欧嘉悦网络科技有限公司' ? null :  companyName === '青岛因特信息科技有限责任公司' ? null : companyName === '青岛中微世纪信息科技有限公司' ? null : <div onClick={openHref} className='footer-href'>鲁ICP备2020047345号-1</div>
            }
            </div>
        </div>
    )
}

export default Footer