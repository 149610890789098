import React from 'react';
import ReactDOM from 'react-dom';
import App from "./app"
import {Button} from "antd"
import "antd/dist/antd.css"
ReactDOM.render(
  <App />,
  document.getElementById('root')
);


