import React from "react";

import p8 from "../../assets/images/p8.png"

const Cell = ({item}) =>{
  console.log("aaa=>",item)
  return (
    <section style={{flexDirection:item.reverse?"row-reverse":"row"}}>
      <div className="content-item">
        <div className="item-title">{item.title}</div>
        <div className="item-titleEnglish">{item.titleEnglish}</div>
        <div className="item-blue-piece"></div>
        <div className="item-content">{item.content}</div>
        <div className="icon-group">
          {
            (item.childList||[]).map(value=>{
              return (
                <div className="icon-cell">
                  <img src={value.icon} alt={value.text} />
                  <div>{value.text}</div>
                </div>
              )
            })
          }
        </div>
        <div className="item-but" onClick={item.toPage}>
          <img src={p8} />
          {/* 了解更多{'>'} */}
        </div>
      </div>
      <div className="content-img">
        <img src={item.imgUrl} alt="" />
      </div>
    </section>
  )
}
export default Cell;