import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './sharing.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'


function InformationSharing(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        margin: "0 auto",
        padding: "60px 160px",
    
    };


    return(
        <div style={{width:'100%'}}>
            <Header current="信用信息共享服务" active={1}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                </div>
                <div className='foodSafety-box1'>
                    <div className='foodSafety-box1-font2'>信用信息共享服务</div>
                    <div className='foodSafety-box1-font3'>Credit information sharing services</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p2} style={{height:80,width:95}}/>
                        <div className='foodSafety-box2-font1'>年报可视化追踪</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p3} style={{height:80,width:70}}/>
                        <div className='foodSafety-box2-font1'>地图网格规范管理</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p4} style={{height:80,width:71}}/>
                        <div className='foodSafety-box2-font1'>年度任务自动提醒</div>
                    </div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}><div className='drugSafety-font1'>建立电子地图普查分析平台，将市场主体信息抓取到地图，同步匹配市场监管人员执法终端，实现市场主体现存状态、许可信息等情况一图呈现、可视管理、反向核验。有效杜绝虚报地址、开办皮包公司、僵尸企业等现象</div>
                </div>
                <div className='foodSafety-box2' style={{height:350}}>
                    <img src={p5} style={{height:428,width:600}}/>
                </div>
                <div className='foodSafety-box1' style={{marginTop:100,marginBottom:60}}>
                    <div className='foodSafety-box1-font2'>产品中心</div>
                    <div className='foodSafety-box1-font3'>Product Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:890}}>
                        <div className='foodSafety-box1-font2'>年报可视化追踪</div>
                        <div className='foodSafety-box1-font3'>Visual tracking of annual reports</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>基于网格化地图可视系统，为辖区监管人员自动编译本年度待年报企业，并呈现在可视化地图上，解放表格式统筹、纸质化统计等工作方式，以可视化地图标点+网格化规范管理，为年报统筹人员实现动态追踪。</div>
                    </div>
                    <img src={p6} className='imformationSharing-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p7} className='imformationSharing-box3-img2'/>
                    <div className='foodSafety-box3' style={{paddingLeft:150,width:950}}>
                        <div className='foodSafety-box1-font2'>地图网格规范管理</div>
                        <div className='foodSafety-box1-font3'>Map mesh specification management</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>构建智慧监管数字作战图，建立可视的信用信息数据监管地图；落实网格化责任制管理，实现基于辖区监管、乡镇/街道、社区、网格分类管理划分，构筑监管协同的基础数据共享应用</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:930,marginLeft:-100}}>
                        <div className='foodSafety-box1-font2'>主体共享普查移动端</div>
                        <div className='foodSafety-box1-font3'>The subject shares the census mobile terminal</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>基于手机移动端，为监管人员、网格人员增强市场监管基础能力，加快推进智慧监管落地，实现企业的手绘定位普查、负责人电话采集、经营状态聚焦等应用，打造市场主体数据共享、状态互通。</div>
                    </div>
                    <img src={p8} className='imformationSharing-box3-img3'/>
                </div>
                
            </div>
        </div>
    )

}

export default InformationSharing 