import React,{ useState, useEffect} from 'react'
import './home.css'
import Header from '../../components/header'
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
import p1 from './imgs/p1.png'
import p1Older from './imgs/p1-older.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'
import p11 from './imgs/p11.png'
import p12 from './imgs/p12.png'
import p13 from './imgs/p13.png'
import p14 from './imgs/p14.png'
import p15 from './imgs/p15.png'
import p16 from './imgs/p16.png'
import p17 from './imgs/p17.png'
import p18 from './imgs/p18.png'
import p19 from './imgs/p19.png'
import p20 from './imgs/p20.png'
import p21 from './imgs/p21.png'
import p22 from './imgs/p22.png'
import p23 from './imgs/p23.png'
import p24 from './imgs/p24.png'
import p25 from './imgs/p25.png'
import p26 from './imgs/p26.png'
import bottom1 from './imgs/bottom1.png'
import bottom2 from './imgs/bottom2.png'
import bottom3 from './imgs/bottom3.png'
import bottom4 from './imgs/bottom4.png'
import bottom5 from './imgs/bottom5.png'
import icon1 from './imgs/icon1.png'
import icon2 from './imgs/icon2.png'
import icon3 from './imgs/icon3.png'
import icon4 from './imgs/icon4.png'
import icon5 from './imgs/icon5.png'
import icon6 from './imgs/icon6.png'
import icon7 from './imgs/icon7.png'
import icon8 from './imgs/icon8.png'
import icon9 from './imgs/icon9.png'
import icon10 from './imgs/icon10.png'
import icon11 from './imgs/icon11.png'
import icon12 from './imgs/icon12.png'
import icon13 from './imgs/icon13.png'
import icon14 from './imgs/icon14.png'
import icon15 from './imgs/icon15.png'
import icon16 from './imgs/icon16.png'
import icon17 from './imgs/icon17.png'
import icon18 from './imgs/icon18.png'
import icon19 from './imgs/icon19.png'
import icon20 from './imgs/icon20.png'
import icon21 from './imgs/icon21.png'
import icon22 from './imgs/icon22.png'
import icon23 from './imgs/icon23.png'
import icon24 from './imgs/icon24.png'
import icon25 from './imgs/icon25.png'
import icon26 from './imgs/icon26.png'
import icon27 from './imgs/icon27.png'
import icon28 from './imgs/icon28.png'
import icon29 from './imgs/icon29.png'
import {Divider,Carousel } from 'antd'
import { DigitalFlop ,Decoration2} from '@jiaminghi/data-view-react'
import { companyName } from '../../api/commonSrc';

function Home(props){

    const borderBg1 = {
        width:"100%",
        height:"37rem",
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        margin: "0 auto",
    };

    const borderBg2 = {
        backgroundImage: 'url(' +p7 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };
    const borderBg3 = {
        backgroundImage: 'url(' +p8 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };
    const borderBg4 = {
        backgroundImage: 'url(' +p9 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };
    const borderBg5 = {
        backgroundImage: 'url(' +p10 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginLeft: 20
    };
    const borderBg6 = {
        height: 420,
        width: 393,
        backgroundImage: 'url(' +p11 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginLeft: 20
    };
    const borderBg7 = {
        height: 200,
        width: 1027,
        backgroundImage: 'url(' +p12 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginTop: 20
    };

    const borderBg8 = {
        backgroundImage: 'url(' +p14 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginRight:20,
        marginLeft:0
    };
    const borderBg9 = {
        backgroundImage: 'url(' +p15 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        display: 'flex'
    };
    const borderBg10 = {
        height: 200,
        width: 1440,
        backgroundImage: 'url(' +p17 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };
    const borderBg11 = {
        height: 200,
        width: 1440,
        backgroundImage: 'url(' +p18 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };

    const borderBg12 = {
        backgroundImage: 'url(' +p19 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };
    const borderBg13 = {
        backgroundImage: 'url(' +p20 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginLeft: 20
    };
    const borderBg14 = {
        height: 420,
        width: 393,
        backgroundImage: 'url(' +p22 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginLeft: 20
    };
    const borderBg15 = {
        height: 200,
        width: 1027,
        backgroundImage: 'url(' +p21 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginTop: 20
    };

    const borderBg16 = {
        backgroundImage: 'url(' +p23 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginRight:20,
        marginLeft:0
    };
    const borderBg17 = {
        backgroundImage: 'url(' +p24 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        display: 'flex'
    };

    const borderBg18 = {
        height: 200,
        width: 1027,
        backgroundImage: 'url(' +p25 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    };
    const borderBg19 = {
        height: 200,
        width: 393,
        backgroundImage: 'url(' + p26 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginLeft:20
    };
    const toPage = (url) =>{
      console.log("跳转中");
      props.history.push(url);     
    }

    

    return( 
        <div style={{width:'100%'}}>
            <Header current="数字政府" active={1}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}} >
            {/* <OverPack > */}
            {/* <QueueAnim delay={500} duration={2200} type={'right'} > */}
           
                {
                    companyName === '中国联合网络通信集团有限公司' ? <div><img style={{ width:"100%",height:"37rem"}} src={p1} /></div>
                    :  companyName === '青岛因特信息科技有限责任公司' ? <div><img style={{ width:"100%",height:"37rem"}} src={p1} /></div>
                    :  companyName === '青岛欧嘉悦网络科技有限公司' ? <div><img style={{ width:"100%",height:"37rem"}} src={p1} /></div>
                    :  companyName === '青岛中微世纪信息科技有限公司' ? <div><img style={{ width:"100%",height:"37rem"}} src={p1} /></div>
                    : <div>
                        <Carousel autoplay style={{zIndex:'-100'}}>
                            <div ><img style={{ width:"100%",height:"37rem"}} src={p1Older} /></div>
                            <div><img style={{ width:"100%",height:"37rem"}} src={p1} /></div>
                        </Carousel>
                    </div>
                } 
            
                    {/* <DigitalFlop config={config} style={{width: '200px', height: '50px'}} /> */}
                    {/* <div className='home-top-font1'>28736251</div>
                    <div className='home-top-font2'>累计用户</div>
                    <div className='home-top-font1'>100+</div>
                    <div className='home-top-font2'>服务部门</div>
                    <div className='home-top-font1'>30+</div>
                    <div className='home-top-font2'>服务行业</div> */}
                <div><Decoration2 style={{width: '100%', height: '5px',dur:15,marginTop:-3}} color={['#FF9100', 'green']} /></div>
                <div className='home-top-box2' key="b">
                    <div className='home-top-box3'>
                        <div style={{height:3,width:82,backgroundColor:'#076CE0',marginTop:5,marginRight:20}}></div>
                        业务范围
                        <div style={{height:3,width:82,backgroundColor:'#076CE0',marginTop:5,marginLeft:20}}></div>
                    </div> 
                    <div className='top-box2-font1'>基于业务场景，为您定制专属的头等舱</div>
                    <div className='home-top-box4'>
                    {/* <QueueAnim delay={500} duration={2000} className="queue-simple" type={'bottom'}> */}
                        <img src={p2} className='home-top-font3' key="img1"/>
                        <img src={p3} className='home-top-font3' key="img2"/>
                        <img src={p4} className='home-top-font3' key="img3"/>
                        <img src={p5} className='home-top-font3' key="img4"/>
                        <img src={p6} className='home-top-font3' key="img5"/>
                    {/* </QueueAnim> */}
                    </div>
                </div>
                <div className='home-top-box2' style={{height:2340}} key="c">
                    <div className='home-top-box3'>
                        <div style={{height:3,width:82,backgroundColor:'#076CE0',marginTop:5,marginRight:20}}></div>
                        案例介绍
                        <div style={{height:3,width:82,backgroundColor:'#076CE0',marginTop:5,marginLeft:20}}></div>
                    </div> 
                    <div className='top-box2-font1'>推动政府各业务场景的数字化、智慧化转型，实现从量到质、从数字到智慧</div>
                    <div className='home-top-box4' style={{paddingTop:30}}>
                        <div onClick={()=>toPage("/foodSafety")} style={borderBg2} className='home-top-img1'>
                            {/* <div className='top-img1-font-box'>
                                <div className='top-img1-font1'>智慧食安</div>
                                <div className='top-img1-font2'>互联网+明厨亮灶</div>
                                <div className='top-img1-font3'></div>
                                <div className='top-img1-font2' style={{fontSize:16}}>基于AI智能，构筑明厨亮灶/透明工厂的闭环监管</div>
                            </div> */}
                        </div>
                        <div onClick={()=>toPage("/transparentPlant")} style={borderBg3} className='home-top-img2'>
                            {/* <div className='top-img2-font-box'>
                                <div className='top-img2-font1'>智慧食安·透明工厂</div>
                                <div className='top-img2-font2'>Smart Food Safety Transparent Factory</div>
                                <div className='top-img2-font3'>建立食品生产分层分级包保督查把控；规范食品生产台账；</div>
                                <div className='top-img2-font3' style={{marginTop:8}}>提升远程视频巡检效率；打造地方性品牌窗口。</div>
                            </div> */}
                        </div>
                    </div>
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div>
                            <div style={{display:'flex'}}>
                                <div onClick={()=>toPage("/drugSafety")} style={borderBg4} className='home-top-img3'>
                                    {/* <div className='top-img3-font-box'>
                                        <div className='top-img3-font1'>互联网+</div>
                                        <div className='top-img3-font2'>智慧药安监管服务</div>
                                        <div className='top-img3-font3'>Smart drug safety supervision services</div>
                                    </div> */}
                                </div>
                                <div onClick={()=>toPage("/specialEquipment")} style={borderBg5} className='home-top-img3'>
                                    {/* <div className='top-img4-font-box'>
                                        <div className='top-img3-font1'>一码多用</div>
                                        <div className='top-img3-font2'>互联网+特种设备监管</div>
                                        <div className='top-img3-font3'>Internet + special equipment supervision</div>
                                    </div> */}
                                </div>
                            </div>
                            <div onClick={()=>toPage("/informationSharing")} style={borderBg7}>
                                {/* <div className='top-img5-font-box'>
                                    <div className='top-img5-font1'>信用信息共享服务</div>
                                    <div className='top-img5-font2'>Credit information sharing services</div>
                                    <div className='top-img5-font3'>建立电子地图普查分析平台，将市场主体信息抓取到地图，同步匹配市场监管人员执法终端，实现市场主体现存状态、许可信息等</div>
                                    <div className='top-img5-font3' style={{marginTop:5}}>情况一图呈现、可视管理、反向核验。有效杜绝虚报地址、开办皮包公司、僵尸企业等现象</div>
                                </div> */}
                            </div>
                        </div>
                        <div>
                            <div onClick={()=>toPage("/coordinateOfficeFlow")} style={borderBg6}>
                                {/* <div className='top-img6-font-box'>
                                    <div className='top-img5-font2' style={{fontSize:15}}>Collaborative office turnover assistant</div>
                                    <div className='top-img6-font1'>协同办公流转助手</div>
                                    <div style={{display:'flex'}}>
                                        <div className='top-img6-font3'>
                                            协助市场监管部门内部之间，建立投诉举报内部流转机制提高投诉的分派、留痕、办结效率；加强事件风险预判、风险研判分析，提升局方对投诉举报事件的区域风险、事件风险、时效风险、时期风险的有效把控。
                                        </div>
                                        <img src={p13} style={{width:55,height:21,marginLeft:20,marginTop:20}}/>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div> 
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div onClick={()=>toPage("/riskHandling")} style={borderBg8} className='home-top-img2'>
                            {/* <div className='top-img7-font1'>风险事件办理中心</div>
                            <img src={p16} className='top-img7-font2'/>
                            <div className='top-img7-font3'>协助市场监管部门内部之间，建立投诉举报内部流转机制提高投诉的分派、留痕、办结效率；加强事件风险预判、风险研判分析，提升局方对投诉举报事件的区域风险、事件风险、时效风险、时期风险的有效把控。</div> */}
                        </div>
                        <div onClick={()=>toPage("/caseNumber")} style={borderBg9} className='home-top-img1'>
                            {/* <div className='top-img8-left'>
                                <div>案件案号内部协同平台</div>
                                <div className='top-img8-left-font1'>Internal coordination platform for case number</div>
                            </div>
                            <div className='top-img8-right'>实现案件案号规范管理助手；构建局内案件重点环节预警把控，预防规避案件程序违规风险；建立多种局内案件数据分析表格统计功能</div> */}
                        </div>
                    </div>
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div onClick={()=>toPage("/foodSafetyGuard")} style={borderBg10}></div>
                    </div>
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div onClick={()=>toPage("/auditLiterature")} style={borderBg11}></div>
                    </div>
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div>
                            <div style={{display:'flex'}}>
                                <div onClick={()=>toPage("/partyBuilding")} style={borderBg12} className='home-top-img3'></div>
                                <div onClick={()=>toPage("/grassroots")} style={borderBg13} className='home-top-img3'></div>
                            </div>
                            <div onClick={()=>toPage("/digitalFarming")} style={borderBg15}></div>
                        </div>
                        <div>
                            <div onClick={()=>toPage("/convenience")} style={borderBg14}></div>
                        </div>
                    </div> 
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div onClick={()=>toPage("/industryAmalgamation")} style={borderBg16} className='home-top-img2'></div>
                        <div onClick={()=>toPage("/safetyRing")} style={borderBg17} className='home-top-img1'></div>
                    </div>
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div onClick={()=>toPage("/peopleslivelihood")} style={borderBg18}></div>
                        <div onClick={()=>toPage("/ruralEcology")} style={borderBg19}></div>
                    </div>
                </div>
                <div className='home-top-box2' style={{height:570}}>
                    <div className='home-top-box3'>
                        <div style={{height:3,width:82,backgroundColor:'#076CE0',marginTop:5,marginRight:20}}></div>
                        创造价值赢得信任
                        <div style={{height:3,width:82,backgroundColor:'#076CE0',marginTop:5,marginLeft:20}}></div>
                    </div> 
                    <div className='top-box2-font1'>用心对待每一位客户，口碑就是我们最有力的品质保障</div>
                    <div className='home-top-box4' style={{marginTop:70}}>
                        <div className='home-bottom-box1'>
                            <img src={bottom1} className='bottom-box1-img1'/>
                            <div className='bottom-box1-font1'>专业团队</div>
                            <div className='bottom-box1-font2'>专注互联网软件研发十余年，具备专业研发能力和项目管理能力</div>
                            <img src={bottom5} className='bottom-box1-img2'/>
                        </div>
                        <div className='home-bottom-box1'>
                            <img src={bottom2} className='bottom-box1-img3'/>
                            <div className='bottom-box1-font1'>私有部署</div>
                            <div className='bottom-box1-font2'>部署到自己的服务器，构建可自行运营的产品服务体系</div>
                            <img src={bottom5} className='bottom-box1-img2'/>
                        </div>
                        <div className='home-bottom-box1'>
                            <img src={bottom3} className='bottom-box1-img4'/>
                            <div className='bottom-box1-font1'>品质保障</div>
                            <div className='bottom-box1-font2'>高标准项目管理规范，严格制定开发流程</div>
                            <img src={bottom5} className='bottom-box1-img2'/>
                        </div>
                        <div className='home-bottom-box1' style={{marginRight:0}}>
                            <img src={bottom4} className='bottom-box1-img5'/>
                            <div className='bottom-box1-font1'>完善服务</div>
                            <div className='bottom-box1-font2'>提供7*24小时响应服务，确保迅速接收需求</div>
                            <img src={bottom5} className='bottom-box1-img2'/>
                        </div>
                    </div>
                </div>
                {
                    companyName === '中国联合网络通信集团有限公司' ? <div style={{height:'4rem'}}></div> :
                    <div className='home-top-box2' style={{height:700}}>
                    <div className='home-top-box3'>
                        <div style={{height:3,width:82,backgroundColor:'#076CE0',marginTop:5,marginRight:20}}></div>
                        生态联盟
                        <div style={{height:3,width:82,backgroundColor:'#076CE0',marginTop:5,marginLeft:20}}></div>
                    </div> 
                    <div className='top-box2-font1'>Ecological Alliance</div>
                    <div className='home-top-box4' style={{marginTop:50}}>
                        <div className='bottom-box2-font1'>
                            <img src={icon1} className='bottom-box2-img1'/>
                            <img src={icon2} className='bottom-box2-img1'/>
                            <img src={icon3} className='bottom-box2-img1'/>
                            <img src={icon4} className='bottom-box2-img1'/>
                            <img src={icon5} className='bottom-box2-img1'/>
                            <img src={icon6} className='bottom-box2-img1'/>
                            <img src={icon7} className='bottom-box2-img1'/>
                        </div>
                    </div>
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div className='bottom-box2-font1'>
                            <img src={icon8} className='bottom-box2-img2'/>
                            <img src={icon9} className='bottom-box2-img2'/>
                            <img src={icon10} className='bottom-box2-img2'/>
                            <img src={icon11} className='bottom-box2-img2'/>
                            <img src={icon12} className='bottom-box2-img2'/>
                            <img src={icon13} className='bottom-box2-img2'/>
                            <img src={icon14} className='bottom-box2-img2'/>
                            <img src={icon15} className='bottom-box2-img2'/>
                        </div>
                    </div>
                    <div className='home-top-box4' style={{marginTop:20}}>
                        <div className='bottom-box2-font1' style={{display:'inline',height:200}}>
                            <div style={{width:'100%'}}>
                            <img src={icon16} className='bottom-box2-img1'/>
                            <img src={icon17} className='bottom-box2-img1'/>
                            <img src={icon18} className='bottom-box2-img1'/>
                            <img src={icon19} className='bottom-box2-img1'/>
                            <img src={icon20} className='bottom-box2-img1'/>
                            <img src={icon21} className='bottom-box2-img1'/>
                            <img src={icon22} className='bottom-box2-img1'/>
                            </div>
                            <div style={{marginTop:20}}>
                            <img src={icon23} className='bottom-box2-img1'/>
                            <img src={icon24} className='bottom-box2-img1'/>
                            <img src={icon25} className='bottom-box2-img1'/>
                            <img src={icon26} className='bottom-box2-img1'/>
                            <img src={icon27} className='bottom-box2-img1'/>
                            <img src={icon28} className='bottom-box2-img1'/>
                            <img src={icon29} className='bottom-box2-img1'/>
                            </div>
                        </div>
                    </div>
                </div>
                }
               
                {/* </QueueAnim> */}
                {/* </OverPack> */}
            </div>
            
            
        </div>
        
    )

}

export default Home