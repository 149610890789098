import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import foodSafetyImg from "../../assets/images/食安BANNER3@2x.png";
import systemHomeImg from "../../assets/images/u1002.png";
import icon7 from "../../assets/images/图像 7@2x.png";
import icon8 from "../../assets/images/图像 8@2x.png";
import icon9 from "../../assets/images/图像 9@2x.png";
import icon10 from "../../assets/images/图像 10@2x.png";
import icon11 from "../../assets/images/图像 11@2x.png";
import foodSecurityImg from "../../assets/images/组 898@2x.png";
import aiVideoInspectionImg from "../../assets/images/组 897@2x.png";
import foodSecurityDispatchImg from "../../assets/images/组 522@2x.png";
import foodSecuritySuperviseImg from "../../assets/images/组 896@2x.png";
import foodSecurityPromotionImg from "../../assets/images/组 895@2x.png";
import frameworkImg from "../../assets/images/组 825@2x.png";
import u2170Img from "../../assets/images/u2170@2x.png";
import u2175Img from  "../../assets/images/u2175@2x.png"
import z911Img from "../../assets/images/组 911@2x.png";
import z912Img from  "../../assets/images/组 912@2x.png"

const FoodSafetyGuard = (props) => {
  const cellList = [{
    imgUrl:icon7,
    text:"教育食安监管"
  },{
    imgUrl:icon8,
    text:"食材诚信供应"
  },{
    imgUrl:icon9,
    text:"学校动态自查"
  },{
    imgUrl:icon10,
    text:"家长互联监督"
  },{
    imgUrl:icon11,
    text:"合理膳食规范"
  }];
  const list = [
    {
      title:"教育部门食安监管",
      titleEnglish:"Food safety regulation in the education sector",
      content:"规范学校及学校周边食堂的食品安全工作流程，建立有效的食品供给溯源、自查自检、家委会巡查、校园陪餐监管等动态可视数据监管。",
      imgUrl:u2170Img,
      reverse:false, // 排序，图片在y
    },{
      title:"大宗食材诚信供应",
      titleEnglish:"Bulk ingredients are supplied in good faith",
      content:"供应商权益，建立供应商资质及配送评分机制，同步通过统一订购平台，保护入围供应商供应合理权益",
      imgUrl:z911Img ,
      reverse:true, // 排序，图片在y
    },{
      title:"学校食堂动态自查",
      titleEnglish:"School canteen dynamic self-inspection",
      content:"从纸质台账，变为便捷的电子台账。提升学校食堂采购效率、保障学校食堂食材采购质量、帮助学校管理者建立动态可视的互联网巡检机制。",
      imgUrl:z912Img,
      reverse:false, // 排序，图片在y
    },{
      title:"家长互联监督",
      titleEnglish:"Parental Connected Supervision",
      content:"利用互联网平台传播的优势，实现多数人监督少数人，建立学校食堂餐饮公示、公开机制。",
      imgUrl:u2175Img,
      reverse:true, // 排序，图片在y
    }
  ];
  return (
    <div className="foodSafety-guard-page">
      <Header current="校园食安卫士" active={2} history={props.history} />
      <div className="bazaarImgCell">
        <img src={foodSafetyImg} alt="校园食安卫士" />
      </div>
      <div className="summarize">
        <div className="title">校园食安卫士</div>
        <div className="english-title">Smart Food Safety Transparent Factory</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  <div>{_.text}</div>
                </div>
              )
            })
          }
        </div>
        <div className="describe">结合当前校园食品安全监管措施，及各学校食堂在采购、安全管理等规范经营方面；为学校及大宗商品配送供应商提供信息化“帮手”，为家长提供数据查询“助手”，为教育部门及市场监管部门提供数据“抓手”。</div>
        <div className="classification-brief-group">
          <div style={{borderTop:"#B9E7FB solid 11px"}}>服务供应商，保护入围供应商供货权</div>
          <div style={{borderTop:"#91D6EE solid 11px"}}>服务学校食堂，保障供给安全，朔源可追，价格合理，质量可评</div>
          <div style={{borderTop:"#75C9D5 solid 11px"}}>服务教育监管部门，提升监管手段，落实供应商诚信，守护学生和家长权益</div>
          <div style={{borderTop:"#2E48DE solid 11px"}}>服务市场监管部门，落实价格督促，保障食材质量，建立学校朔源管控</div>
          <div style={{borderTop:"#6B428F solid 11px"}}>服务学生家长，提升社会共治，公示公开明厨亮灶，提升诚信信息共治</div>
        </div>
        <img className="system-home-img" src={frameworkImg} alt="校园食安卫士建设架构" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default FoodSafetyGuard;