import React from "react";

const Cell = (props) =>{
  let {item} = props;
  console.log("aaa=>",props)
  return (
    <section style={{}}>
      {
        item.notShow?null:(<div className="content-item">
          <div className="item-title">{item.title}</div>
          <div className="item-titleEnglish">{item.titleEnglish}</div>
          <div className="item-blue-piece"></div>
          <div className="item-content">{item.content}</div>
        </div>)
      }
      <div className="content-img">
        {/* <img src={item.imgUrl} alt="" /> */}
        {props.children}
      </div>
    </section>
  )
}
export default Cell;