import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './riskHandling.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'


function RiskHandling(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        margin: "0 auto",
        padding: "60px 160px",
    
    };


    return(
        <div style={{width:'100%'}}>
            <Header current="风险事件办理中心" active={1}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                </div>
                <div className='foodSafety-box1'>
                    <div className='foodSafety-box1-font2'>风险事件办理中心</div>
                    <div className='foodSafety-box1-font3'>Risk Event Management Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}>
                    <div className='riskHandling-box2-img1'>
                        <img src={p2} style={{height:85,width:80}}/>
                        <div className='foodSafety-box2-font1'>规范受理</div>
                    </div>
                    <div className='riskHandling-box2-img1'>
                        <img src={p3} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>可视流转</div>
                    </div>
                    <div className='riskHandling-box2-img1'>
                        <img src={p4} style={{height:80,width:67}}/>
                        <div className='foodSafety-box2-font1'>留痕办理</div>
                    </div>
                    <div className='riskHandling-box2-img1'>
                        <img src={p5} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>统计分析</div>
                    </div>
                    <div className='riskHandling-box2-img1'>
                        <img src={p6} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>风险研判</div>
                    </div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}><div className='drugSafety-font1'>协助市场监管部门内部之间，建立投诉举报内部流转机制提高投诉的分派、留痕、办结效率；加强事件风险预判、风险研判分析，提升局方对投诉举报事件的区域风险、事件风险、时效风险、时期风险的有效把控。</div>
                </div>
                <div className='foodSafety-box2' style={{height:350}}>
                    <img src={p7} style={{height:450,width:900}}/>
                </div>
                <div className='foodSafety-box1' style={{marginTop:100,marginBottom:60}}>
                    <div className='foodSafety-box1-font2'>产品中心</div>
                    <div className='foodSafety-box1-font3'>Product Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:950,marginLeft:-100}}>
                        <div className='foodSafety-box1-font2'>事件办理中心</div>
                        <div className='foodSafety-box1-font3'>Event Center</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>建立风险事件的受理分派管理，提升承办人员规范投诉办理，实现局内投诉受理、分派、审批、办结答复流程化管理体制，提升投诉的答复率及答复规范。</div>
                    </div>
                    <img src={p8} className='riskHandling-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p9} className='riskHandling-box3-img2'/>
                    <div className='foodSafety-box3' style={{paddingLeft:150,width:950}}>
                        <div className='foodSafety-box1-font2'>事件追踪统计</div>
                        <div className='foodSafety-box1-font3'>Event tracking statistics</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>实现建立投诉统计分析平台，实现投诉环节统计、投诉类型统计、投诉来源统计、投诉承办部门工作量等数据统计。支持根据所需数据表格导出</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:780}}>
                        <div className='foodSafety-box1-font2'>风险事件研判分析</div>
                        <div className='foodSafety-box1-font3'>Risk event research and analysis</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>为局方建立数字化分析模型，实现对投诉事件的分类、分析及风险研判，建立投诉举报大数据分析平台，建立投诉的区域风险、事件风险、时效风险及时期风险。</div>
                    </div>
                    <img src={p7} className='riskHandling-box3-img3'/>
                </div>
                
            </div>
        </div>
    )

}

export default RiskHandling 