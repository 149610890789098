import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import transparentPlantImg from "../../assets/images/透明工厂BANNER.png";
import systemHomeImg from "../../assets/images/u1002.png";
import icon26 from "../../assets/images/图像 26.png";
import icon27 from "../../assets/images/图像 27.png";
import icon28 from "../../assets/images/图像 28.png";
import icon29 from "../../assets/images/图像 29.png";
import icon30 from "../../assets/images/图像 30.png";
import foodSecurityImg from "../../assets/images/组 898@2x.png";
import aiVideoInspectionImg from "../../assets/images/组 897@2x.png";
import foodSecurityDispatchImg from "../../assets/images/组 522@2x.png";
import foodSecuritySuperviseImg from "../../assets/images/组 896@2x.png";
import foodSecurityPromotionImg from "../../assets/images/组 895@2x.png";

const TransparentPlant = (props) => {
  const cellList = [{
    imgUrl:icon26,
    text:"食安分层分级"
  },{
    imgUrl:icon27,
    text:"规范生产"
  },{
    imgUrl:icon28,
    text:"任务调度"
  },{
    imgUrl:icon29,
    text:"品牌推广"
  },{
    imgUrl:icon30,
    text:"远程巡检"
  }];
  const list = [
    {
      title:"食安分层分级监管平台",
      titleEnglish:"Food safety hierarchical supervision platform",
      content:"统筹分析食安分层分级包保督查任务工作情况，建立健全督查清单制度。各级督查组对包保干部落实责任清单、任务清单的情况，尤其是对是否履职尽责、是否完成任务进行督查。根据督查结果，基于数字化平台分析对工作不得力、责任不落实的进行警示约谈、限期整改并视情通报。",
      imgUrl:foodSecurityImg,
      reverse:false, // 排序，图片在y
    },{
      title:"AI智能远程视频巡检平台",
      titleEnglish:"AI intelligent remote video inspection platform",
      content:"利用AI 算法技术对视频、图片的智能分析， 打造“互联网+食安透明工厂的闭环监管”模式，实现在发现生产过程中的违规行为后， 对违规行为进行及时抓取，解决生产食品生产单位常见的违规行为处理不及时、追责无证据的问题。",
      imgUrl:aiVideoInspectionImg,
      reverse:true, // 排序，图片在y
    },{
      title:"食品生产任务指挥调度平台",
      titleEnglish:"Food production task command and dispatch platform",
      content:"建立各所/各科室监管人员与食品生产单位之间的留痕化任务下发跟踪调度应用；实现基于每件任务安排都有留痕化跟踪，食品生产单位者谁看了，谁回复了，当前任务的阅览率等情况，为监管者提供可视化的任务统筹把控及调度。",
      imgUrl:foodSecurityDispatchImg,
      reverse:false, // 排序，图片在y
    },{
      title:"食安规范生产监督监管平台",
      titleEnglish:"Food safety standardize production supervision and supervision platform",
      content:"支持食品生产单位通过平台端或小程序服务端上报食安规范生产台账，支持进货查验记录/关键控制点记录/定期排查食品安全风险隐患记录/留样记录/出厂检验记录/不合格管理和食品召回记录/食品安全自查记录等台账建立报送窗口；",
      imgUrl:foodSecuritySuperviseImg,
      reverse:true, // 排序，图片在y
    },{
      title:"地方食安品牌化推广服务",
      titleEnglish:"Local food safety brand promotion services",
      content:"借助官方微信公众服务平台，实现 针对本地不同类型食品生产单位，对规范度较高、生产环境良好的食品生产单位及食品作坊建立定期的有效推广，扩大食品生产单位的知名度，在规范企业生产意识的同时，市场监管部门同步为企业建立品牌化宣传，优商助企。",
      imgUrl:foodSecurityPromotionImg,
      reverse:false, // 排序，图片在y
    },
  ];
  return (
    <div className="transparent-plant-page">
      <Header current="智慧食安·透明工厂" active={1} history={props.history} />
      <div className="bazaarImgCell">
        <img src={transparentPlantImg} alt="透明工厂" />
      </div>
      <div className="summarize">
        <div className="title">智慧食安·透明工厂</div>
        <div className="english-title">Smart Food Safety Transparent Factory</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  <div>{_.text}</div>
                </div>
              )
            })
          }
        </div>
        <div className="describe">建立食品生产分层分级包保督查把控；规范食品生产台账；提升远程视频巡检效率；打造地方性品牌窗口。</div>
        <img className="system-home-img" src={systemHomeImg} alt="系统首页" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default TransparentPlant;