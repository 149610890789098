/**
 * 产业融合
 */
import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import bigImgs from "../../assets/images/产业6@2x.png";
import TaskDeliveryImg from "../../assets/images/驾驶舱5@2x.png";
import cellIcon1 from "../../assets/images/图像 160@2x.png";
import cellIcon2 from "../../assets/images/图像 161@2x.png";
import cellIcon3 from "../../assets/images/图像 162@2x.png";
import cellIcon4 from "../../assets/images/图像 163@2x.png";
import cellIcon5 from "../../assets/images/图像 164@2x.png";
import cellIcon6 from "../../assets/images/图像 165@2x.png";


import cellImg1 from "../../assets/images/组 926@2x.png";
import cellImg2 from "../../assets/images/组 927@2x.png";
import cellImg3 from "../../assets/images/组 582@2x.png";
import cellImg4 from "../../assets/images/组 928@2x.png";
import cellImg5 from "../../assets/images/组 929@2x.png";
import cellImg6 from "../../assets/images/组 930@2x.png";
import cellImg7 from "../../assets/images/组 931@2x.png";
import cellImg8 from "../../assets/images/组 932@2x.png";


const IndustryAmalgamation = (props) => {
  const cellList = [{
    imgUrl:cellIcon1,
    text:"产业融合"
  },{
    imgUrl:cellIcon2,
    text:"机械农耕"
  },{
    imgUrl:cellIcon3,
    text:"赋闲零工"
  },{
    imgUrl:cellIcon4,
    text:"耕地租赁"
  },{
    imgUrl:cellIcon5,
    text:"助农合作社"
  },{
    imgUrl:cellIcon6,
    text:"农资服务站"
  }];
  const list = [
    {
      title:"红色产业融合",
      titleEnglish:"Red industry integration",
      content:"规范红色教育活动、加强红色教育培训，开展红色研学数字展馆，讲好革命故事，传承红色基因。结合红色研学，设计红色文创内容，运用漫画、表情包等虚拟形象IP，讲述地区红色文化的前世今生。",
      imgUrl:cellImg1,
      reverse:false, // 排序，图片在y
      width:624,
      height:250
    },{
      title:"农耕产业融合",
      titleEnglish:"Integration of farming industry",
      content:"结合优势资源，打造生态文旅场景，结合自然资源与农创IP，通过农耕文化研学旅行，了解农耕文化、领略农耕生活、品文化魅力、感历史变迁。",
      imgUrl:cellImg2,
      reverse:true, // 排序，图片在y
      width:629,
      height:396
    },{
      // blueTitle:"党群服务中心",
      title:"特色产品融合",
      titleEnglish:"Featured product fusion",
      content:"持续价值的输出，加深经济作物品牌标签立足丰富的资源优势，举办采摘节，进一步提高村庄知名度，打响特色农业品牌实现特色产业做大做强。",
      imgUrl:cellImg3,
      reverse:false, // 排序，图片在y
      width:602,
      height:364,
      rowHeight:578

    },{
      // blueTitle:"党群服务中心",
      title:"机械农耕",
      titleEnglish:"Mechanical farming",
      content:"协助乡镇农民建立耕种互通通道,有耕种机械的农民可以为需要耕种机械的农民，提供机械。",
      imgUrl:cellImg4,
      reverse:true, // 排序，图片在y
      width:382,
      height:396,
      rowHeight:582
    },{
      // blueTitle:"党群服务中心",
      title:"闲赋零工",
      titleEnglish:"Idle odd jobs",
      content:"协助乡镇农民建立赋闲在家的零工共享门户，闲置在家的人可以通过闲赋零工助手查看需要农民零工的需求方，既让闲赋在家的人找到工作的地方，又为需求方解决了招人难的问题，实现了消息的互通。",
      imgUrl:cellImg5,
      reverse:false, // 排序，图片在y
      width:385,
      height:390,
      rowHeight:564
    },{
      // blueTitle:"党群服务中心",
      title:"计划农耕",
      titleEnglish:"Plan farming",
      content:"协助乡镇农民建立耕地资源租赁互通通道，统筹耕地流转数据体制建立",
      imgUrl:cellImg6,
      reverse:true, // 排序，图片在y
      width:382,
      height:402,
      rowHeight:578
    },{
      // blueTitle:"党群服务中心",
      title:"助农合作社",
      titleEnglish:"Agricultural cooperatives",
      content:<div>解决乡镇内部区域的短频快购销:农产品、林业花卉等提供销路和获取渠道，在一定范围内解决了农产品交易信息不对称和流通成本高等问题，取得了良好的经济效益和社会效益<br />提升农工与工厂的互助共赢：既解决了农工产品无销路又解决了工厂招工情况，实现信息共享</div>,
      imgUrl:cellImg7,
      reverse:false, // 排序，图片在y
      width:492,
      height:392,
      rowHeight:578
    },{
      // blueTitle:"党  群服务中心",
      title:"农资服务站",
      titleEnglish:"Agricultural input service stations",
      content:"实现农资互联，便民搜索，助商惠企。打造农资供应网，为农民和各类新型农业经营主体提供质优价廉的农资产品",
      imgUrl:cellImg8,
      reverse:true, // 排序，图片在y
      width:382,
      height:332,
      rowHeight:582
    }
  ];
  return (
    <div className="industry-amalgamation-page">
      <Header current="产业融合" active={4} history={props.history} />
      <div className="bazaarImgCell">
        <img src={bigImgs} alt="透明工厂" />
      </div>
      <div className="summarize">
        <div className="title">产业融合</div>
        <div className="english-title">Industrial convergence</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  <div>{_.text}</div>
                </div>
              )
            })
          }
        </div>
        <div className="describe">投诉数据受理的统计分析应用平台，以及通过投诉事件建立有效的风险预警数字化模型，实现投诉风险预警，提升局方区域风险、事件风险、时效风险、时期风险的有效把控措施。</div>
        <img className="system-home-img" style={{width:766,height:600}} src={TaskDeliveryImg} alt="系统首页" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default IndustryAmalgamation;