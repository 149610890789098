import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './digitalFarming.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'
import p11 from './imgs/p11.png'
import p12 from './imgs/p12.png'
import p13 from './imgs/p13.png'
import p14 from './imgs/p14.png'
import p15 from './imgs/p15.png'
import p16 from './imgs/p16.png'
import p17 from './imgs/p17.png'
import p18 from './imgs/p18.png'
import p19 from './imgs/p19.png'


function DigitalFarming(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        margin: "0 auto",
        padding: "60px 160px",
    
    };


    return(
        <div style={{width:'100%'}}>
            <Header current="数字三农" active={4}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                </div>
                <div className='foodSafety-box1'>
                    <div className='foodSafety-box1-font2'>数字三农</div>
                    <div className='foodSafety-box1-font3'>Government affairs are convenient for the people</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p2} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>数字农耕</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p3} style={{height:80,width:89}}/>
                        <div className='foodSafety-box2-font1'>数字养殖</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p4} style={{height:80,width:70}}/>
                        <div className='foodSafety-box2-font1'>数字农业</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p5} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>农业IOT物联网管理</div>
                    </div>
                </div>
                <div className='foodSafety-box2' style={{height:160}}><div className='drugSafety-font1' style={{lineHeight:2}}>平台运用云计算、人工智能、物联网、3S等先进技术，建设面向核心业务层的分布式云服务框架。通过基础云平台服务建设，统一农业农村信息化规范，为数据中心、一张图+N场景的应用提供基础的环境和支撑。建设内容包括基础云框架平台、3S平台服务体系、物联网传输体系等。实现涉农数据资源的整合共享、数据融合、分析应用。为推进数字技术在农业生产、质量监管、态势感知、综合分析、预警预测、辅助决策等领域的智慧应用。</div>
                </div>
                <div className='foodSafety-box2' style={{height:350}}>
                    <img src={p6} style={{height:428,width:600}}/>
                </div>
                <div className='foodSafety-box1' style={{marginTop:100,marginBottom:60}}>
                    <div className='foodSafety-box1-font2'>产品中心</div>
                    <div className='foodSafety-box1-font3'>Product Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:1000,marginLeft:0}}>
                        <div className='foodSafety-box1-font2'>数字农耕</div>
                        <div className='foodSafety-box1-font3'>Digital farming</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>以“管理+服务”为核心思想，打造区农业农村场景应用体系。充分结合现状、从实际出发，聚焦核心业务，逐步建设集智慧种植、特色农产品、农产品质量安全、数字种业管理等业务服务场景，助力地方数字农耕发展。</div>
                        <div className='digitalFarming-box-left'>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p11} style={{height:59,width:59}}/>
                                <div className='campusSecurity-box2-font1'>智慧种植</div>
                            </div>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p12} style={{height:52,width:49,marginTop:4,marginBottom:3}}/>
                                <div className='campusSecurity-box2-font1'>数字种业</div>
                            </div>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p13} style={{height:56,width:49,marginTop:2,marginBottom:1}}/>
                                <div className='campusSecurity-box2-font1'>农机资产</div>
                            </div>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p14} style={{height:56,width:31,marginTop:2,marginBottom:1}}/>
                                <div className='campusSecurity-box2-font1'>特色农产品</div>
                            </div>
                        </div>
                    </div>
                    <img src={p7} className='convenience-box3-img1' style={{height:372,width:523}}/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p8} className='imformationSharing-box3-img2' style={{height:457,width:602}}/>
                    <div className='foodSafety-box3' style={{width:800,paddingLeft:50}}>
                        <div className='foodSafety-box1-font2'>数字养殖</div>
                        <div className='foodSafety-box1-font3'>Digital farming</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>汇聚本地各类动物存栏、出栏等畜禽养殖监管情况，进行畜禽养殖业情况集中展示，按专栏分类展示；让养殖行业依托着互联网深度融合物联IOT技术、智能算法等展现出全新的面貌，推动着粗放式养殖向知识型、技术型、现代化的智慧养殖转变。更科学更智能的规避风险、更加便捷的养殖生产管理</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:900,marginLeft:0}}>
                        <div className='foodSafety-box1-font2'>数字农业</div>
                        <div className='foodSafety-box1-font3'>Digital agriculture</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1' style={{marginTop:30}}>依托农业大数据平台的数据优势，整合汇聚，最终形成面向农业农村产业的综合化数据农业服务中心平台。涵盖项目管理、、投入品安全、专家咨询服务、产业服务、行政执法等数据汇聚、收集、加工治理工作，实现承上对接上层数据中心，启下统一汇聚现有业务单元数据，并最终完成数据综合服务体系，实现数字农业的规范化建设。</div>
                        <div className='digitalFarming-box-left' style={{marginTop:40}}>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p15} style={{height:57,width:57}}/>
                                <div className='campusSecurity-box2-font1'>项目管理</div>
                            </div>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p16} style={{height:57,width:46}}/>
                                <div className='campusSecurity-box2-font1'>投入品安全</div>
                            </div>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p17} style={{height:57,width:53}}/>
                                <div className='campusSecurity-box2-font1'>专家咨询服务</div>
                            </div>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p18} style={{height:57,width:57}}/>
                                <div className='campusSecurity-box2-font1'>产业服务</div>
                            </div>
                            <div className='digitalFarming-box2-img1'>
                                <img src={p19} style={{height:57,width:54}}/>
                                <div className='campusSecurity-box2-font1'>行政执法</div>
                            </div>
                        </div>
                    </div>
                    <img src={p9} className='convenience-box3-img1' style={{height:200,width:628}}/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p10} className='imformationSharing-box3-img2' style={{height:350,width:623}}/>
                    <div className='foodSafety-box3' style={{width:800,paddingLeft:50}}>
                        <div className='foodSafety-box1-font2'>农业IOT物联网管理平台</div>
                        <div className='foodSafety-box1-font3'>Agricultural IoT IoT management platform</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>“农业物联设备设施”的投入使用能够显著提高农业生产经营效率。基于精准的农业传感器进行实时监测，利用云计算、数据挖掘等技术进行多层次分析，提高了农业生产对自然环境风险的应对能力，使弱势的传统农业成为具有高效率的现代产业。</div>
                    </div>
                </div>
                
 
            </div>
        </div>
    )

}

export default DigitalFarming 