import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import specialEquipmentImg from "../../assets/images/协同办公@2x.png"
import TaskDeliveryImg from "../../assets/images/u1600@2x.png";
import cellImg1 from "../../assets/images/组 902@2x.png";
import cellImg2 from "../../assets/images/组 903@2x.png";
import cellImg3 from "../../assets/images/组 904@2x.png";
import cellImg4 from "../../assets/images/组 905@2x.png";
import cellImg5 from "../../assets/images/组 906@2x.png";
import icon40 from "../../assets/images/图像 40.png";
import icon41 from "../../assets/images/图像 41.png";
import icon42 from "../../assets/images/图像 42.png";
import officialDocProcessImg from "../../assets/images/公文及文件流转中心ps@2x.png";
import lawsRegulationsImg from "../../assets/images/法律法规智能检索中心ps@2x.png";
import TaskNotificationImg from "../../assets/images/组 525@2x.png";

const CoordinateOfficeFlow = (props) => {
  const cellList = [{
    imgUrl:icon40,
    text:"公文痕迹流转"
  },{
    imgUrl:icon41,
    text:"文献检索助理"
  },{
    imgUrl:icon42,
    text:"任务通知落实"
  }];
  const list = [
    {
      title:"公文及文件流转中心",
      titleEnglish:"Official Documents and Document Circulation Center",
      content:"实现部门与部门之间、人员与人员之间、科室与科室之间各种类型文书的转办、流转、留痕、统计等工作应用。文书流转到哪、谁看了、谁进行了转办、谁又在转办中增加了信息等一系列的文书流转应用，实现全程可视。",
      imgUrl:officialDocProcessImg,
      reverse:false, // 排序，图片在y
    },{
      title:"法律法规智能检索中心",
      titleEnglish:"Intelligent search center for laws and regulations",
      content:"构建法律法规、行政处罚、自由裁量智能查询库，实现根据行为、条款、内容等多方面智能筛查，建立有效的法律依据查询手段，为一线执法人员建立智能有效的法律法规查询助手。",
      imgUrl:lawsRegulationsImg,
      reverse:true, // 排序，图片在y
    },{
      title:"企业任务通知中心",
      titleEnglish:"Enterprise Task Notification Center",
      content:"建立监管人员与企业之间的留痕化任务通知下发跟踪应用；实现基于每件任务通知的安排都有留痕化跟踪，企业者什么时间看的、落实的答复情况、当前任务通知的阅览率情况；为监管者提供可视化的任务统筹把控。",
      imgUrl:TaskNotificationImg,
      reverse:false, // 排序，图片在y
    }
  ];
  return (
    <div className="transparent-plant-page">
      <Header current="协同办公流转助手" active={1} history={props.history} />
      <div className="bazaarImgCell">
        <img src={specialEquipmentImg} alt="透明工厂" />
      </div>
      <div className="summarize">
        <div className="title">协同办公流转助手</div>
        <div className="english-title">Collaborative office turnover assistant</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          <div></div>
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  <div>{_.text}</div>
                </div>
              )
            })
          }
          <div></div>
        </div>
        <div className="describe">建立规范的公文通知的流转、留痕、统计助理，为我们工作人员提供查、用助手服务；打造便捷的法规文献智能查询助手等应用；切实发挥信息化端查询、流转、留痕、统计的作用，积极推动业务部门的数字化建设。</div>
        <img className="system-home-img" src={TaskDeliveryImg} alt="系统首页" />
        <div className="title" style={{marginTop:"180px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default CoordinateOfficeFlow;