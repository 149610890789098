import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './foodSafety.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'
import p11 from './imgs/p11.png'
import p12 from './imgs/p12.png'
import p13 from './imgs/p13.png'
import p14 from './imgs/p14.png'
import p15 from './imgs/p15.png'
import p16 from './imgs/p16.png'
import p17 from './imgs/p17.png'
import p18 from './imgs/p18.png'
import p19 from './imgs/p19.png'
import p20 from './imgs/p20.png'

function FoodSafety(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition:"center",
        margin: "0 auto",
        padding: "4rem 8rem",
        // backgroundSize:'fixed',
    };

    const borderBg2 = {
        width: 759,
        height:204,
        backgroundImage: 'url(' +p8 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    
    };
    const borderBg3 = {
        width: 175,
        height:246,
        backgroundImage: 'url(' +p9 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
    
    };
    const borderBg4 = {
        width: 175,
        height:246,
        backgroundImage: 'url(' +p10 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginLeft: 20
    
    };
    const borderBg5 = {
        width: 175,
        height:246,
        backgroundImage: 'url(' +p11 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginLeft: 20
    
    };
    const borderBg6 = {
        width: 175,
        height:246,
        backgroundImage: 'url(' +p12 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        marginLeft: 20
    
    };

    return(
        <div style={{width:'100%'}}>
            <Header current="互联网+明厨亮灶" active={1}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                    <div className='top-img1-font-box' style={{width:'50rem'}}>
                        <div className='foodSafety-img1-font1'>智慧食安</div>
                        <div className='foodSafety-img1-font2'>互联网+明厨亮灶</div>
                        <div className='foodSafety-img1-font3'></div>
                        <div className='foodSafety-img1-font2' style={{fontSize:"1.5rem"}}>基于AI智能，构筑明厨亮灶/透明工厂的闭环监管</div>
                    </div> 
                </div>
                <div className='foodSafety-box1'>
                    <div className='foodSafety-box1-font2'>互联网+明厨亮灶</div>
                    <div className='foodSafety-box1-font3'>Internet + bright kitchen bright stove</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}>
                    <div className='foodSafety-box2-img1'>
                        <img src={p2} style={{height:80,width:66}}/>
                        <div className='foodSafety-box2-font1'>AI算法服务</div>
                    </div>
                    <div className='foodSafety-box2-img1'>
                        <img src={p3} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>闭环监管</div>
                    </div>
                    <div className='foodSafety-box2-img1'>
                        <img src={p4} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>物联网监测</div>
                    </div>
                    <div className='foodSafety-box2-img1'>
                        <img src={p5} style={{height:80,width:73}}/>
                        <div className='foodSafety-box2-font1'>动态台账</div>
                    </div>
                    <div className='foodSafety-box2-img1'>
                        <img src={p6} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>一企一码</div>
                    </div>
                </div>
                <div className='foodSafety-box2' style={{height:850,marginTop:100}}>
                    <img src={p7} style={{height:811,width:641}}/>
                    <div className='foodSafety-box2-font2'>
                        <div style={borderBg2}>
                            <div className='foodSafety-box2-font3'>优化营商环境，助力餐饮服务行业的诚信体系建设，结合当地监管特色，实现明厨亮灶智能智慧闭环管控，从预警发现到企业收到预警提醒，再到企业整改落实反馈，统筹食安监管的风险聚焦及提炼。</div>
                        </div>
                        <div style={{display:'flex',marginTop:25}}>
                            <div style={borderBg3}>
                                <div className='foodSafety-box2-font3' style={{padding:'65px 26px'}}>通过线上智能巡检，指挥落实风险单位的问题整改；</div>
                            </div>
                            <div style={borderBg4}>
                                <div className='foodSafety-box2-font3' style={{padding:'65px 26px'}}>为企业规范经营落实，减少企业的整改和处罚风险；</div>
                            </div>
                            <div style={borderBg5}>
                                <div className='foodSafety-box2-font3' style={{padding:'65px 26px'}}>让处罚作为辅助，把优商成为主力；</div>
                            </div>
                            <div style={borderBg6}>
                                <div className='foodSafety-box2-font3' style={{padding:'65px 26px'}}>探索新型智慧食安、智慧市场建设的长效运营模式。</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='foodSafety-box1' style={{marginTop:70,marginBottom:60}}>
                    <div className='foodSafety-box1-font2'>产品中心</div>
                    <div className='foodSafety-box1-font3'>Product Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3'>
                        <div className='foodSafety-box1-font2'>食品安全任务指挥调度平台</div>
                        <div className='foodSafety-box1-font3'>Food safety task command and dispatch platform</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>建立各所/各科室监管人员与企业之间的留痕化任务下发跟踪调度应用；实现基于每件任务安排都有留痕化跟踪，企业者谁看了，谁回复了，当前任务的阅览率等情况，为监管者提供可视化的任务统筹把控及调度。</div>
                    </div>
                    <img src={p13} className='foodSafety-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p14} className='foodSafety-box3-img2'/>
                    <div className='foodSafety-box3' style={{paddingLeft:50,width:800}}>
                        <div className='foodSafety-box1-font2'>AI智能预警服务平台</div>
                        <div className='foodSafety-box1-font3'>AI intelligent early warning service platform</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>利用AI 算法技术对视频、图片的智能分析， 打造“互联网+明厨亮灶的闭环监管”模式，实现在发现生产过程中的违规行为后， 对违规行为进行及时抓取，解决生产企业常见的违规行为处理不及时、追责无证据的问题。</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:770,marginLeft:50}}>
                        <div className='foodSafety-box1-font2'>餐饮物联网可视预警平台</div>
                        <div className='foodSafety-box1-font3'>Catering IoT visual early warning platform</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>基于物联网建立餐饮风险告知应用，针对消毒、留样设备建立数据动态采集，系统根据特定规则生产预警指标，并结合预警事件分发给各片区监管所；做到从发现、告知、预警到落实的全流程闭环。</div>
                    </div>
                    <img src={p15} className='foodSafety-box3-img3'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p16} className='foodSafety-box3-img2'/>
                    <div className='foodSafety-box3' style={{paddingLeft:50,width:800}}>
                        <div className='foodSafety-box1-font2'>微服务动态台账报送管理平台</div>
                        <div className='foodSafety-box1-font3'>Microservice dynamic ledger reporting management platform</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>支持企业通过平台端或微信小程序服务端上报规范经营台账，支持自查自检台账/索证索票台账/清洗消毒台账/食品留样台账/食品添加剂备案台账/废弃物处理台账/快检检测台账建立便捷的动态报送窗口；</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3'>
                        <div className='foodSafety-box1-font2'>食安可视化数据分析驾驶舱</div>
                        <div className='foodSafety-box1-font3'>Food safety visual data analysis cockpit</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>打造食安横向食安诚信分析与纵向食安监管分析，构筑企业食安指数系数，并对食安指数分级展示；为监管数字化赋能可视化分析。</div>
                    </div>
                    <img src={p17} className='foodSafety-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p18} className='foodSafety-box3-img2'/>
                    <div className='foodSafety-box3' style={{paddingLeft:50,width:800}}>
                        <div className='foodSafety-box1-font2'>“寻找笑脸”诚信二维码公示系统</div>
                        <div className='foodSafety-box1-font3'>"Looking for a smiley" integrity QR code publicity system</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>诚信信息公示、从业人员公示<br/>明厨亮灶公示<br/>风险、量化等级公示<br/>特色食品安全宣讲基地<br/>监管信息动态发布</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:850,marginLeft:-80}}>
                        <div className='foodSafety-box1-font2'>食安地图小程序</div>
                        <div className='foodSafety-box1-font3'>Food Safety Map Mini Program</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>基于小程序微服务及数据地图技术，实现食安诚信数据公示，对重点环节的视频监控单位构筑诚信公示通道；实现线上食安共治服务窗口，打造在线投诉举报收集，问题反馈收集，支持问题分类汇总，为下一步监管工作建立靶向聚焦。</div>
                    </div>
                    <img src={p19} className='foodSafety-box3-img4'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p20} className='foodSafety-box3-img4'/>
                    <div className='foodSafety-box3' style={{paddingLeft:100,width:850}}>
                        <div className='foodSafety-box1-font2'>官方微信“互联网+明厨亮灶”公示服务系统</div>
                        <div className='foodSafety-box1-font3'>Official WeChat "Internet + Bright Kitchen Bright Stove" publicity service system</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>基于市场监督管理局“官方微信公众服务号”建立“互联网+明厨亮灶”的汇聚公示窗口，实现不同行业/业态的分类展示窗口、诚信信息的动态公示窗口、从业人员的健康证线上公示等应用。</div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FoodSafety 