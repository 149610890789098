/**
 * 案件案号内部协同平台
 */
import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import caseNumberImg from "../../assets/images/案件案号内部协同平台.png@2x.png";
import TaskDeliveryImg from "../../assets/images/案件情况数据统计分析系统@2x.png";
import icon40 from "../../assets/images/组 795@2x.png";
import icon41 from "../../assets/images/组 796@2x.png";
import icon42 from "../../assets/images/组 797@2x.png";
import icon43 from "../../assets/images/组 798@2x.png";
import icon44 from "../../assets/images/组 799@2x.png";
import officialDocProcessImg from "../../assets/images/案件案号规范管理应用@2x.png";
import lawsRegulationsImg from "../../assets/images/案件办理重点环节预警追溯@2x.png";
import TaskNotificationImg from "../../assets/images/案件情况数据统计分析系统@2x.png";


const CaseNumber = (props) => {
  const cellList = [{
    imgUrl:icon40,
    text:"案号规范化管理"
  },{
    imgUrl:icon41,
    text:"办理周期预警"
  },{
    imgUrl:icon42,
    text:"强制环节追踪"
  },{
    imgUrl:icon43,
    text:"案件案由聚焦"
  },{
    imgUrl:icon44,
    text:"案件罚没统计"
  }];
  const list = [
    {
      title:"案件案号规范管理应用",
      titleEnglish:"Case number standardizes management application",
      content:"实现案件案号流程痕迹化，建立一案一库管理，提升办案人员规范案件“文号”，方案办案人员应用，提升法规科工作人员案号管理规范。支持撤案，重启立案号管理应用。",
      imgUrl:officialDocProcessImg,
      reverse:false, // 排序，图片在y
    },{
      title:"案件办理重点环节预警追溯",
      titleEnglish:"Early warning and tracing of key links in case handling",
      content:"实现建立案件办理周期预警管理、案件强制措施预警管理、案件强制执行预警管理及综合预警追溯助手。实现当前在办案件的整体追溯播报，直至案件结案；",
      imgUrl:lawsRegulationsImg,
      reverse:true, // 排序，图片在y
    },{
      title:"案件情况数据统计分析系统",
      titleEnglish:"Case situation data statistical analysis system",
      content:"实现案件办理数据分析统计，实现建立案件多种统计方式汇聚分析，实现依据办案单位、案件归类、案件程序、案件来源进行，立案数统计、案件罚没统计。为局方提供有效的案件数据分析助手。",
      imgUrl:TaskNotificationImg,
      reverse:false, // 排序，图片在y
    }
  ];
  return (
    <div className="case-number-page">
      <Header current="案件案号内部协同平台" active={1} history={props.history} />
      <div className="bazaarImgCell">
        <img src={caseNumberImg} alt="案件案号" />
      </div>
      <div className="summarize">
        <div className="title">案件案号内部协同平台</div>
        <div className="english-title">Internal coordination platform for case number</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  {/* <div>{_.text}</div> */}
                </div>
              )
            })
          }
        </div>
        <div className="describe">实现案件案号规范管理助手；构建局内案件重点环节预警把控，预防规避案件程序违规风险；建立多种局内案件数据分析表格统计功能</div>
        <img className="system-home-img" src={TaskDeliveryImg} alt="系统首页" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default CaseNumber;