import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './campusSecurity.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import {baseUrl} from '../../../api/commonSrc'



function CampusSecurity(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        margin: "0 auto",
        padding: "60px 160px",
    
    };


    return(
        <div style={{width:'100%'}}>
            <Header current="校园安全"/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                </div>
                <div className='foodSafety-box2' style={{height:800,marginTop:-50}}>
                    <div className='foodSafety-box3' style={{width:700}}>
                        <div className='foodSafety-box1-font2'>校园食安卫士</div>
                        <div className='foodSafety-box1-font3'>Campus Food Safety Guard</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>面向学校大宗食材采购服务，结合当前校园食品安全监管措施，及各学校食堂在采购、安全管理等规范经营方面；为学校及大宗商品配送供应商提供信息化“帮手”，为家长提供数据查询“助手”，为教育部门及市场监管部门提供数据“抓手”</div>
                        <div className='campusSecurity-box-left'>
                            <div className='riskHandling-box2-img1'>
                                <img src={p3} style={{height:54,width:74}}/>
                                <div className='campusSecurity-box2-font1'>教育食安监管</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p4} style={{height:54,width:83}}/>
                                <div className='campusSecurity-box2-font1'>食材诚信供应</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p5} style={{height:54,width:50}}/>
                                <div className='campusSecurity-box2-font1'>学校动态自查</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p6} style={{height:54,width:53}}/>
                                <div className='campusSecurity-box2-font1'>家长互联监督</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p7} style={{height:54,width:53}}/>
                                <div className='campusSecurity-box2-font1'>合理膳食规范</div>
                            </div>
                        </div>
                        <div className='campusSecurity-box-left1'>
                            <img src={p8} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/foodSafetyGuard')}/>
                        </div>
                    </div>
                    <img src={p2} className='campusSecurity-box3-img1'/>
                </div>
                
            </div>
        </div>
    )

}

export default CampusSecurity 