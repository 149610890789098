import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import specialEquipmentImg from "../../assets/images/特种设备m.png"
import icon34 from "../../assets/images/图像 34.png";
import icon35 from "../../assets/images/图像 35.png";
import icon36 from "../../assets/images/图像 36.png";
import TaskDeliveryImg from "../../assets/images/u857@2x.png";
import cellImg1 from "../../assets/images/组 902@2x.png";
import cellImg2 from "../../assets/images/组 903@2x.png";
import cellImg3 from "../../assets/images/组 904@2x.png";
import cellImg4 from "../../assets/images/组 905@2x.png";
import cellImg5 from "../../assets/images/组 906@2x.png";


const SpecialEquipment = (props) => {
  const cellList = [{
    imgUrl:icon34,
    text:"培训学习评比"
  },{
    imgUrl:icon35,
    text:"一码多用监管"
  },{
    imgUrl:icon36,
    text:"任务追踪管理"
  }];
  const list = [
    {
      title:"作业人员培训学习评比服务",
      titleEnglish:"Operator training and learning evaluation services",
      content:"打造培训学习平台，为作业人员提供权威的安全学习园地，包含例如五个必须、每日八查、十不准等等信息。同步基于作业人员学习情况，为设备使用单位建立安全学习系数，打造特种设备监管的纵向安全评比机制。",
      imgUrl:cellImg1,
      reverse:false, // 排序，图片在y
    },{
      title:"任务下发动态追踪",
      titleEnglish:"Dynamic tracking of task delivery",
      content:"监管人员根据相关指导文件，下发至相关特种设备使用单位；任务追踪系统将为监管人员提供哪家企业看了，哪家企业已经针对文件进行答复了，当前任务答复率、查阅率等统计指标，为特种设备监管提供数字化帮手。",
      imgUrl:cellImg2,
      reverse:true, // 排序，图片在y
    },{
      blueTitle:"监管场景",
      title:"的一码多用",
      titleEnglish:"One yard for multiple uses in regulatory scenarios",
      content:"基于系统为每台特种设备生成的一物一码，实现监管者或网格人员扫描设备二维码建立监督检查，网格员登录工作人员小程序后，通过扫码实现对本设备快速建立安全监督检查；",
      imgUrl:cellImg3,
      reverse:false, // 排序，图片在y
    },{
      blueTitle:"企业场景",
      title:"的一码多用",
      titleEnglish:"One code for multiple purposes in enterprise scenarios",
      content:"基于系统为每台特种设备生成的一物一码，实现企业人员通过企业小程序登录后扫码，显示本台设备的维护信息、检验信息、监督检查信息及风险预防信息",
      imgUrl:cellImg4,
      reverse:true, // 排序，图片在y
    },{
      blueTitle:"公众场景",
      title:"的一码多用",
      titleEnglish:"One yard for multiple uses in public scenes",
      content:"基于系统为每台特种设备生成的一物一码，实现公众扫码后，显示本台设备的基础信息、特种设备使用单位信息、检验状态、风险问题提报等功能",
      imgUrl:cellImg5,
      reverse:false, // 排序，图片在y
    },
  ];
  return (
    <div className="transparent-plant-page">
      <Header current="特种设备一码多用监管" active={1} history={props.history} />
      <div className="bazaarImgCell">
        <img src={specialEquipmentImg} alt="透明工厂" />
      </div>
      <div className="summarize">
        <div className="title">特种设备一码多用监管</div>
        <div className="english-title">Special equipment one-yard multi-purpose supervision</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          <div></div>
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  <div>{_.text}</div>
                </div>
              )
            })
          }
          <div></div>
        </div>
        <div className="describe">特种设备数智化监管服务系统，构建企业特种设备信息集成化、特种设备隐患巡检数字化、特种设备安全培训体系化、特种设备监管智能化、特种设备分布统计可视化五大监管体系。通过直观、便捷、高效的数据统计分析引擎，结合电子地图及地理信息技术，实现特种设备使用单位分布及特种设备信息的一图集成、精准定位。</div>
        <img className="system-home-img" src={TaskDeliveryImg} alt="系统首页" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default SpecialEquipment;