import React, { useEffect } from "react";
import Header from "../../components/header/index";
import "./index.less";
import bazaarImg from "../../assets/images/市场.png";
import img2 from "../../assets/images/透明工厂.png";
import img3 from "../../assets/images/智慧药房.png";
import img4 from "../../assets/images/特种设备.png";
import img5 from "../../assets/images/信用信息共享.png";
import img6 from "../../assets/images/协同办公.png";
import img7 from "../../assets/images/风险事件办理中心.png";
import img8 from "../../assets/images/案件案号内部协同平台.png";
// import pharmacyImg from "../../assets/images/智慧药房.png"
import Cell from "./cell";
import zhihueishian from "../../assets/images/明厨亮灶.png";
import aiIcon from "../../assets/images/图像 21.png";
import closedLoopIcon from "../../assets/images/图像 22.png";
import InternetOfThingsIcon from "../../assets/images/图像 23.png"
import DynamicLedgerIcon from "../../assets/images/图像 24.png";
import EnterpriseQRcodeIcon from "../../assets/images/图像 25.png";
import icon26 from "../../assets/images/图像 26.png";
import icon27 from "../../assets/images/图像 27.png";
import icon28 from "../../assets/images/图像 28.png";
import icon29 from "../../assets/images/图像 29.png";
import icon30 from "../../assets/images/图像 30.png";
import icon31 from "../../assets/images/图像 31.png";
import icon33 from "../../assets/images/图像 33.png";
import icon34 from "../../assets/images/图像 34.png";
import icon35 from "../../assets/images/图像 35.png";
import icon36 from "../../assets/images/图像 36.png";
import icon37 from "../../assets/images/图像 37.png";
import icon38 from "../../assets/images/图像 38.png";
import icon39 from "../../assets/images/图像 39.png";
import icon40 from "../../assets/images/图像 40.png";
import icon41 from "../../assets/images/图像 41.png";
import icon42 from "../../assets/images/图像 42.png";
import icon43 from "../../assets/images/图像 43.png";
import icon44 from "../../assets/images/图像 44.png";
import icon45 from "../../assets/images/图像 45.png";
import icon46 from "../../assets/images/图像 46.png";
import icon47 from "../../assets/images/图像 47.png";
import icon48 from "../../assets/images/图像 48.png";
import icon49 from "../../assets/images/图像 49.png";
import icon50 from "../../assets/images/图像 50.png";
import icon51 from "../../assets/images/图像 51.png";
import icon52 from "../../assets/images/图像 52.png";
import icon711 from "../../assets/images/组 711.png";
import icon712 from "../../assets/images/组 712.png";
import icon713 from "../../assets/images/组 713.png";
import icon714 from "../../assets/images/组 714.png";

const SmartMarket = (props) =>{
  const cellList = [
    {
      title:"互联网+明厨亮灶",
      titleEnglish:"Internet + Bright kitchen bright stove",
      content:"优化营商环境，助力餐饮服务行业的诚信体系建设，结合当地监管特色，实现明厨亮灶智能智慧闭环管控，从预警发现到企业收到预警提醒，再到企业整改落实反馈，统筹食安监管的风险聚焦及提炼。",
      imgUrl:zhihueishian,
      reverse:false, // 排序，图片在y，
      toPage:()=>{
        props.history.push('/foodSafety')
      },
      childList:[
        {
          icon:aiIcon,
          text:"AI算法服务"
        },{
          icon:closedLoopIcon,
          text:"闭环监管"
        },{
          icon:InternetOfThingsIcon,
          text:"物联网监测"
        },{
          icon:DynamicLedgerIcon,
          text:"动态台账"
        },{
          icon:EnterpriseQRcodeIcon,
          text:"一企一码"
        }
      ]
    },{
      title:"智慧食安·透明工厂",
      titleEnglish:"Smart Food Safety Transparent Factory",
      content:"建立食品生产分层分级包保督查把控；规范食品生产台账；提升远程视频巡检效率；打造地方性品牌窗口。",
      imgUrl:img2,
      reverse:true, // 排序，图片在y，
      toPage:()=>{
        props.history.push('/transparentPlant')
      },
      childList:[
        {
          icon:icon26,
          text:"食安分层分级"
        },{
          icon:icon27,
          text:"规范生产"
        },{
          icon:icon28,
          text:"任务调度"
        },{
          icon:icon29,
          text:"品牌推广"
        },{
          icon:icon30,
          text:"远程巡检"
        }
      ]
    },{
      title:"智慧药安监管服务",
      titleEnglish:"Smart drug safety supervision services",
      content:"药品监管台账动态可视，涵盖：药品使用单位、零售药店、医疗器械经营、化妆品经营、化妆品使用等单位的数据台账多元化管理；药物警戒智能预防，对临期过期、购进异常、追溯异常建立智能化识别预防；药品监管信息数字可视，完善对药品、医疗器械、化妆品的数字化动态监管体制",
      imgUrl:img3,
      reverse:false,
      toPage:()=>{
        props.history.push('/drugSafety')
      },
      childList:[
        {
          icon:icon712,
          text:"药品"
        },{
          icon:icon713,
          text:"化妆品"
        },{
          icon:icon714,
          text:"医疗器械"
        },{
          icon:icon31,
          text:"监管台账"
        },{
          icon:icon711,
          text:"药品警戒"
        },{
          icon:icon33,
          text:"数字可视"
        }
      ]
    },{
      title:"特种设备一码多用监管",
      titleEnglish:"Special equipment one-yard multi-purpose supervision",
      content:"特种设备数智化监管服务系统，构建企业特种设备信息集成化、特种设备隐患巡检数字化、特种设备安全培训体系化、特种设备监管智能化、特种设备分布统计可视化五大监管体系。通过直观、便捷、高效的数据统计分析引擎，结合电子地图及地理信息技术，实现特种设备使用单位分布及特种设备信息的一图集成、精准定位。",
      imgUrl:img4,
      reverse:true,
      toPage:()=>{
        props.history.push('/specialEquipment')
      },
      childList:[
        {
          icon:icon34,
          text:"培训学习评比"
        },{
          icon:icon35,
          text:"一码多用监管"
        },{
          icon:icon36,
          text:"任务追踪管理"
        }
      ]
    },{
      title:"信用信息共享服务",
      titleEnglish:"Credit information sharing services",
      content:"建立电子地图普查分析平台，将市场主体信息抓取到地图，同步匹配市场监管人员执法终端，实现市场主体现存状态、许可信息等情况一图呈现、可视管理、反向核验。有效杜绝虚报地址、开办皮包公司、僵尸企业等现象",
      imgUrl:img5,
      reverse:false,
      toPage:()=>{
        props.history.push('/informationSharing')
      },
      childList:[
        {
          icon:icon37,
          text:"年报可视化追踪"
        },{
          icon:icon38,
          text:"地图网格规范管理"
        },{
          icon:icon39,
          text:"年度任务自动提醒"
        }
      ]
    },{
      title:"协同办公流转助手",
      titleEnglish:"Collaborative office turnover assistant",
      content:"建立规范的公文通知的流转、留痕、统计助理，为我们工作人员提供查、用助手服务；打造便捷的法规文献智能查询助手等应用；切实发挥信息化端查询、流转、留痕、统计的作用，积极推动业务部门的数字化建设。",
      imgUrl:img6,
      reverse:true,
      toPage:()=>{
        props.history.push('/coordinateOfficeFlow')
      },
      childList:[
        {
          icon:icon40,
          text:"公文痕迹流转"
        },{
          icon:icon41,
          text:"文献检索助理"
        },{
          icon:icon42,
          text:"任务通知落实"
        }
      ]
    },{
      title:"风险事件办理中心",
      titleEnglish:"Risk Event Management Center",
      content:"协助市场监管部门内部之间，建立投诉举报内部流转机制提高投诉的分派、留痕、办结效率；加强事件风险预判、风险研判分析，提升局方对投诉举报事件的区域风险、事件风险、时效风险、时期风险的有效把控。",
      imgUrl:img7,
      reverse:false,
      toPage:()=>{
        props.history.push('/riskHandling')
      },
      childList:[
        {
          icon:icon43,
          text:"规范受理"
        },{
          icon:icon44,
          text:"可视流转"
        },{
          icon:icon45,
          text:"留痕办理"
        },{
          icon:icon46,
          text:"统计分析"
        },{
          icon:icon47,
          text:"风险研判"
        }
      ]
    },{
      title:"案件案号内部协同平台",
      titleEnglish:"Internal coordination platform for case number",
      content:"实现案件案号规范管理助手；构建局内案件重点环节预警把控，预防规避案件程序违规风险；建立多种局内案件数据分析表格统计功能",
      imgUrl:img8,
      reverse:true,
      toPage:()=>{
        props.history.push('/caseNumber')
      },
      childList:[
        {
          icon:icon48,
          text:"案号规范化管理"
        },{
          icon:icon49,
          text:"办理周期预警"
        },{
          icon:icon50,
          text:"强制环节追踪"
        },{
          icon:icon51,
          text:"案件案由聚焦"
        },{
          icon:icon52,
          text:"案件罚没统计"
        }
      ]
    }
  ]
  useEffect(()=>{
  
  },[]);
  return (
    <div className="smart-market-page">
      {/*  history={props.history} */}
      <Header current="智慧市场" />
      <div className="bazaarImgCell">
        <img src={bazaarImg} alt="" />
      </div>
      {
        cellList.map(item=>{
          return <Cell item={item} />
        })
      }
    </div>
  )
}
export default SmartMarket;