import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import bigImgs from "../../assets/images/尾页BANNER@2x.png";
import companyProfileImg from "../../assets/images/组 972@2x.png"
import p1 from "../../assets/images/p1.png" 
import p2 from "../../assets/images/p2.png"
import p3 from "../../assets/images/p3.png"
import p4 from "../../assets/images/p4.png"
import TaskDeliveryImg from "../../assets/images/服务流程@2x.png";

import cellImg1 from "../../assets/images/wx001.png";
import cellImg2 from "../../assets/images/wx002.png";
import cellImg3 from "../../assets/images/wx003.png";
import {companyProfile1,companyName1,companyProfile2 ,companyName} from '../../api/commonSrc'




const AboutUs = (props) => {
  return (
    <div className="about-us-page">
      <Header current="关于我们" active={5} history={props.history} />
      <div className="bazaarImgCell">
        <img src={bigImgs} alt="透明工厂" />
      </div>
      <div className="company-profile">
        <img src={companyName === '济南云科睿信息科技有限公司' ? p1 : companyName === '青岛因特信息科技有限责任公司' ? p4 : companyName === '青岛欧嘉悦网络科技有限公司' ? p4 : companyName === '青岛中微世纪信息科技有限公司' ? p4 : companyProfileImg} style={{width:583,height:797}} alt="公司照片" />
        <div className="centent">
          <div className="title">公司简介</div>
          <div className="english-title">Company Profile</div>
          <div className="item-blue-piece"></div>
          <div className="content-text">
          {companyProfile1}
          </div>
          <div className="content-text" style={{marginTop:"0"}}>
            {companyProfile2}
          </div>
        </div>
      </div>
      <div className="summarize">
        <div className="title">专业的服务流程</div>
        <div className="english-title">Professional service process</div>
        {/* <div className="item-blue-piece"></div> */}
        <img className="service-flow" src={TaskDeliveryImg} alt="" />
        
        <div className="title">未来&愿景</div>
        <div className="english-title">打破传统开发模式，运用互联网+思维，不断努力，坚持创造，{companyName1}一直在前进</div>
        <div className="group">
          <img src={cellImg1} alt="展望未来" />
          <img src={cellImg2} alt="数字转型" />
          <img src={cellImg3} alt="精益求精" />
        </div>
      </div>
    </div>
  )
}
export default AboutUs;