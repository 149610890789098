import React, { useState, useEffect } from 'react'
import './index.less'
import Header from '../../components/header'
import topP1 from './imgs/topP1.gif'
import topP2 from './imgs/topP2.gif'
import topP3 from './imgs/topP3.gif'
import topP4 from './imgs/topP4.gif'
import topblack from './imgs/topblack.svg'
import content1 from './imgs/content1.gif'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import brack1 from './imgs/brack1.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'
import p11 from './imgs/p11.png'
import brack2 from './imgs/brack2.png'
import brack3 from './imgs/brack3.png'
import p12 from './imgs/p12.png'
import p13 from './imgs/p13.png'
import p14 from './imgs/p14.png'
import p15 from './imgs/p15.png'
import p16 from './imgs/p16.png'
import brack4 from './imgs/brack4.png'
import p17 from './imgs/p17.png'
import p18 from './imgs/p18.png'
import p19 from './imgs/p19.png'
import p20 from './imgs/p20.gif'
import brack5 from './imgs/brack5.png'
import brack6 from './imgs/brack6.png'
import brack7 from './imgs/brack7.png'
import brack8 from './imgs/brack8.png'
import brack9 from './imgs/brack9.png'
import brack10 from './imgs/brack10.png'
import brack11 from './imgs/brack11.png'
import brack12 from './imgs/brack12.png'
import brack13 from './imgs/brack13.png'
import p21 from './imgs/p21.png'
import brack14 from './imgs/brack14.png'
import p22 from './imgs/p22.png'
import p23 from './imgs/p23.png'
import p24 from './imgs/p24.png'
import p25 from './imgs/p25.png'
import p26 from './imgs/p26.png'
import p27 from './imgs/p27.png'
import p28 from './imgs/p28.png'
import bottom1 from './imgs/bottom1.png'
import bottom2 from './imgs/bottom2.png'
import bottom3 from './imgs/bottom3.png'
import bottom4 from './imgs/bottom4.png'
import bottom5 from './imgs/bottom5.png'
import bottom6 from './imgs/bottom6.png'
import bottom7 from './imgs/bottom7.png'
import bottom8 from './imgs/bottom8.png'
import bottom9 from './imgs/bottom9.png'
import bottom10 from './imgs/bottom10.png'
import bottom11 from './imgs/bottom11.png'
import bottom12 from './imgs/bottom12.png'
import code1 from './imgs/code1.png'

function EcoChain(props) {

    const borderBg1 = {
        backgroundImage: 'url(' + brack1 + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };

    const borderBg2 = {
        backgroundImage: 'url(' + brack2 + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };

    const borderBg3 = {
        backgroundImage: 'url(' + brack3 + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };

    const borderBg4 = {
        backgroundImage: 'url(' + brack5 + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };
    const borderBg5 = {
        backgroundImage: 'url(' + brack6 + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };
    const borderBg6 = {
        backgroundImage: 'url(' + brack7 + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };
    const borderBg7 = {
        backgroundImage: 'url(' + brack8 + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };
    const borderBg8 = {
        backgroundImage: 'url(' + brack14 + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
    };


    const openWindowUrl = () => {
        window.open(`https://qiaolianyun.com/ecochain/#/login`)
    }

    return (
        <div className='ecochain-content'>
            <Header current="首页" active={0} />
            <div className='ecochain-box1'>
                <img src={topblack} className='top-black-img' />
                <img src={topP1} className='top-img1' />
                <img src={topP2} className='top-img2' />
                <img src={topP3} className='top-img3' />
                <img src={topP4} className='top-img4' />
                <div className='top-box1'>
                    <div className='top-box1-div1'>桥联<div className='top-box1-font1'>生态</div>链</div>
                    <div className='top-box1-div1'>产业<div className='top-box1-font2'>赋能</div>服务平台</div>
                </div>
                <div className='top-box2'>
                    <div className='top-box2-div1'>欢迎来到<div className='top-box2-font1'>桥联生态链</div></div>
                    <div className='top-box2-div2'>桥联生态链，为您提供全链条的  产品质量溯源与服务提升；</div>
                    <div className='top-box2-div2'>为您打通数字名片、品牌故事、企业宣传、产品中心、资质荣誉等窗口，为您的品质赋能助力。</div>
                    <div className='top-box2-div3'>
                        <div className='div3-button' onClick={openWindowUrl}>打开“桥联生态链”平台</div>
                    </div>
                    <div className='top-box2-div4'>Open the "Bridge Link Ecological Chain" platform</div>
                </div>
                <img src={code1} className='top-box3'/>
                {/* <div className='top-box3'></div> */}
            </div>
            <div className='ecochain-box2'>
                <div className='ecochain-box2-title'>您遇到过这样的“<div className='box2-title-font1'>难题!</div>”么？</div>
                <div className='ecochain-box2-content'>
                    <img src={content1} style={{ height: '31vw' }} />
                    <div className='box2-content-right'>
                        <img src={p1} className='right-p1' />
                        <img src={p2} className='right-p2' />
                        <img src={p3} className='right-p3' />
                        <img src={p4} className='right-p4' />
                        <img src={p5} className='right-p5' />
                        <img src={p7} className='right-p7' />
                        <img src={p6} className='right-p6' />
                    </div>
                </div>
            </div>
            <div className='ecochain-box2' style={{ backgroundColor: 'rgba(249,249,249,1)' }}>
                <div className='ecochain-box2-title'>有一种沟通，叫做“<div className='box2-title-font2'>我把材料分享给您!</div>”</div>
                <div className='ecochain-box2-content1'>
                    <div className='box2-content1-bottom' style={borderBg1}>
                        <img src={p8} className='content1-bottom-p1' />
                        <div className='content1-bottom-font1' style={borderBg2}>方式 01</div>
                        <div className='content1-bottom-box1' style={borderBg3}>
                            <div className='content1-bottom-font2'>员工数字名片</div>
                            <img src={p12} className='content1-bottom-p2' />
                            <div className='content1-bottom-font3'>通过微信随时将自己的名片通过小程序分享出去；包含：个人介绍、职务介绍、联系电话、微信名片、个人格言、企业介绍等等内容</div>
                        </div>
                    </div>
                    <div className='box2-content1-bottom1' style={borderBg1}>
                        <img src={p9} className='content1-bottom-p1' />
                        <div className='content1-bottom-font1' style={borderBg2}>方式 02</div>
                        <div className='content1-bottom-box1' style={borderBg3}>
                            <div className='content1-bottom-font2'>产品数字彩页</div>
                            <img src={p12} className='content1-bottom-p2' />
                            <div className='content1-bottom-font3'>通过微信随时将公司各类方案、彩页、相关产品的介绍通过小程序分享出去；让客户快速聚焦每类产品的具体介绍；不占内存、永不过期、后台随时更新。</div>
                        </div>
                    </div>
                    <div className='box2-content1-bottom' style={borderBg1}>
                        <img src={p10} className='content1-bottom-p1' />
                        <div className='content1-bottom-font1' style={borderBg2}>方式 03</div>
                        <div className='content1-bottom-box1' style={borderBg3}>
                            <div className='content1-bottom-font2'>企业品牌故事</div>
                            <img src={p12} className='content1-bottom-p2' />
                            <div className='content1-bottom-font3'>通过微信随时将公司宣传视频、品牌故事、资质荣誉等内容分享给客户；随时更新数据、不占手机内存、永不过期随时查看。</div>
                        </div>
                    </div>
                    <div className='box2-content1-bottom1' style={borderBg1}>
                        <img src={p11} className='content1-bottom-p1' />
                        <div className='content1-bottom-font1' style={borderBg2}>方式 04</div>
                        <div className='content1-bottom-box1' style={borderBg3}>
                            <div className='content1-bottom-font2'>品质溯源生态链</div>
                            <img src={p12} className='content1-bottom-p2' />
                            <div className='content1-bottom-font3'>通过微信随时可以将过程链分享出去，从开始服务、到过程跟踪、再到最终完结，全程溯源留痕，为您的服务品质提升，助力数字赋能</div>
                        </div>
                    </div>
                </div>
                <img src={p13} className='content1-bottom-p3' />
            </div>
            <div className='ecochain-box3'>
                <img src={brack4} className='ecochain-box2-p5' />
                <div className='box3-content1'>
                    <div className='box3-content1-left'>
                        <img src={p14} style={{ height: '100%' }} />
                        <div className='content1-left-div'>
                            <div className='box3-content1-p1'>独立的专属名片</div>
                            <div className='box3-content1-p2'>Separate and exclusive business cards</div>
                        </div>
                    </div>
                    <div className='box3-content1-left'>
                        <img src={p15} style={{ height: '100%' }} />
                        <div className='content1-left-div'>
                            <div className='box3-content1-p1'>丰富的产品中心</div>
                            <div className='box3-content1-p2'>Abundant product center</div>
                        </div>
                    </div>
                    <div className='box3-content1-left'>
                        <img src={p16} style={{ height: '100%' }} />
                        <div className='content1-left-div'>
                            <div className='box3-content1-p1'>全方位的企业介绍</div>
                            <div className='box3-content1-p2'>A full range of corporate presentations</div>
                        </div>
                    </div>
                </div>
                <div className='box3-content2'>
                    <img src={p17} style={{ height: '2.3vw' }} />
                    <div className='content2-div1'>
                        <img src={p19} style={{ width: '60%' }} />
                        <img src={p20} className='content2-div1-img1' />
                    </div>
                    <img src={p18} style={{ height: '2.3vw' }} />
                </div>
                <div className='box3-content3'>
                    <div className='content3-left' style={borderBg4}>分享给朋友</div>
                    <div className='content3-left' style={borderBg5}>分享给客户</div>
                    <div className='content3-left' style={borderBg6}>分享给领导</div>
                    <div className='content3-left' style={borderBg7}>分享给合作伙伴</div>
                </div>
            </div>
            <div className='ecochain-box4'>
                <div className='ecochain-box4-title'>助力企业 “<div style={{ color: 'rgba(10, 119, 251, 1)' }}>品质宣传</div>” 与 “<div style={{ color: 'rgba(10, 119, 251, 1)' }}>品牌延伸</div>” 提升</div>
                <div className='box4-content1'>
                    <div className='content1-left' >
                        <img src={brack9} className='content1-left-img1' />
                        <div className='content1-left-box1'>种植生态链</div>
                    </div>
                    <div className='content1-left' >
                        <img src={brack10} className='content1-left-img1' />
                        <div className='content1-left-box1'>养殖生态链</div>
                    </div>
                    <div className='content1-left' >
                        <img src={brack11} className='content1-left-img1' />
                        <div className='content1-left-box1'>加工生态链</div>
                    </div>
                    <div className='content1-left' >
                        <img src={brack12} className='content1-left-img1' />
                        <div className='content1-left-box1'>生产生态链</div>
                    </div>
                    <div className='content1-left' >
                        <img src={brack13} className='content1-left-img1' />
                        <div className='content1-left-box1'>服务生态链</div>
                    </div>
                    <div className='content1-left1'>...</div>
                </div>
                <div className='box4-content2'>
                    <div className='content2-left'>过程溯源</div>
                    <img src={p21} style={{ width: '3vw', margin: '0 0.6vw 0 1.5vw' }} />
                    <div className='content2-left'>专业防伪技术</div>
                    <img src={p21} style={{ width: '3vw', margin: '0 0.6vw 0 1.5vw' }} />
                    <div className='content2-left'>动态跟踪</div>
                    <img src={p21} style={{ width: '3vw', margin: '0 0.6vw 0 1.5vw' }} />
                    <div className='content2-left'>品质赋码</div>
                </div>
                <div className='box4-content3' style={borderBg8}>
                    <div className='content3-left'>源头链</div>
                    <div className='content3-left'>渠道链</div>
                    <div className='content3-left'>代理链</div>
                    <div className='content3-left'>链链相通</div>
                </div>
            </div>
            <div className='ecochain-box5'>
                <div className='box5-content-left'>
                    <div className='content-left-font1'>一物一码</div>
                    <div className='content-left-font2'><div style={{ fontSize: '2.1vw', marginTop: '-0.7vw' }}>助推</div>企业<div style={{ color: 'rgba(245, 154, 35, 1)' }}>数字化转型</div>升级</div>
                </div>
                <img src={p22} style={{ width: '56vw' }} />
            </div>
            <div className='ecochain-box6'>
                <div className='box6-content-left'>
                    <img src={p23} style={{ width: '100%' }} />
                    <div className='content-left-div1'>
                        <div className='left-div1-font1'>装饰品质链</div>
                        <div className='left-div1-font2'>Decorative quality chain</div>
                    </div>
                    <div className='content-left-div2'>为优质的装修品质赋能<br />从设计定稿、施工跟踪、到验收完工全面溯源追溯，让客户真实了解每个节点品质，提升装修公司的差异化口碑。</div>
                </div>
                <div className='box6-content-left'>
                    <img src={p24} style={{ width: '100%' }} />
                    <div className='content-left-div1'>
                        <div className='left-div1-font1'>家政服务链</div>
                        <div className='left-div1-font2'>Chain of housekeeping services</div>
                    </div>
                    <div className='content-left-div2'>为优质的家政服务赋能<br />从家政服务开始、到服务节点跟踪、再到服务完工全面溯源追溯，让客户对每个服务细节品质全面了解。</div>
                </div>
                <div className='box6-content-left'>
                    <img src={p25} style={{ width: '100%' }} />
                    <div className='content-left-div1'>
                        <div className='left-div1-font1'>施工跟踪链</div>
                        <div className='left-div1-font2'>Construction tracking chain</div>
                    </div>
                    <div className='content-left-div2'>为优质的施工品质赋能<br />从施工作业开始、到施工节点跟踪、再到施工验收全面溯源追溯，让客户对每个施工细节品质全面了解。</div>
                </div>
            </div>
            <div className='ecochain-box6'>
                <div className='box6-content-left'>
                    <img src={p26} style={{ width: '100%' }} />
                    <div className='content-left-div1'>
                        <div className='left-div1-font1'>茶叶种植链</div>
                        <div className='left-div1-font2'>Tea planting chain</div>
                    </div>
                    <div className='content-left-div2'>为优质的有机绿色茶园赋能<br />从种植、采摘、炒制、装盒全面溯源追溯，真实体现茶叶的优质品质</div>
                </div>
                <div className='box6-content-left'>
                    <img src={p27} style={{ width: '100%' }} />
                    <div className='content-left-div1'>
                        <div className='left-div1-font1'>海参养殖链</div>
                        <div className='left-div1-font2'>Sea cucumber farming chain</div>
                    </div>
                    <div className='content-left-div2'>为优质的有机野生海参赋能<br />从海参养殖过程、日常管理、加工制作、到装盒上市全面溯源追溯，真实体现海参的优质品质</div>
                </div>
                <div className='box6-content-left'>
                    <img src={p28} style={{ width: '100%' }} />
                    <div className='content-left-div1'>
                        <div className='left-div1-font1'>畜牧业养殖链</div>
                        <div className='left-div1-font2'>Animal husbandry chain</div>
                    </div>
                    <div className='content-left-div2'>为优质的牛羊肉养殖赋能<br />从牛羊养殖过程、日常管理、加工制作、到包装上市全面溯源追溯，真实体现牛羊肉的优质品质</div>
                </div>
            </div>
            <div className='ecochain-box7'>
                <div className='ecochain-box7-title'>“桥联生态链”带您赋能更多经营场景</div>
                <div className='ecochain-box7-content1'>
                    <div className='box7-content1-left'>
                        <img src={bottom1} className='content1-left-img1' />
                        <div className='content1-left-font1'>酒水经营</div>
                        <div className='content1-left-font2'>Wine</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom2} className='content1-left-img1' />
                        <div className='content1-left-font1'>培训机构</div>
                        <div className='content1-left-font2'>Training institutions</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom3} className='content1-left-img1' />
                        <div className='content1-left-font1'>汽车维修店</div>
                        <div className='content1-left-font2'>Car repair shop</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom4} className='content1-left-img1' />
                        <div className='content1-left-font1'>酒店住宿</div>
                        <div className='content1-left-font2'>Hotel</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom5} className='content1-left-img1' />
                        <div className='content1-left-font1'>干洗店</div>
                        <div className='content1-left-font2'>dry cleaner</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom6} className='content1-left-img1' />
                        <div className='content1-left-font1'>熟食店</div>
                        <div className='content1-left-font2'>Deli</div>
                    </div>
                </div>
                <div className='ecochain-box7-content1' style={{marginBottom:'5vw'}}>
                    <div className='box7-content1-left'>
                        <img src={bottom7} className='content1-left-img1' />
                        <div className='content1-left-font1'>咨询机构</div>
                        <div className='content1-left-font2'>advisory body</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom8} className='content1-left-img1' />
                        <div className='content1-left-font1'>家政保洁</div>
                        <div className='content1-left-font2'>Housekeeping</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom9} className='content1-left-img1' />
                        <div className='content1-left-font1'>门窗安装</div>
                        <div className='content1-left-font2'>Windows Installation</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom10} className='content1-left-img1' />
                        <div className='content1-left-font1'>定制家具</div>
                        <div className='content1-left-font2'>Custom-made furniture</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom11} className='content1-left-img1' />
                        <div className='content1-left-font1'>冷链配送</div>
                        <div className='content1-left-font2'>Cold chain distribution</div>
                    </div>
                    <div className='box7-content1-left'>
                        <img src={bottom12} className='content1-left-img1' />
                        <div className='content1-left-font1'>蛋糕店</div>
                        <div className='content1-left-font2'>Cake shop</div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default EcoChain