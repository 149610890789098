import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import bigPicture from "../../assets/images/蒙版组 7@2x.png";
import z57Img from "../../assets/images/组 57@2x.png";
import icon102 from "../../assets/images/图像 102@2x.png";
import icon101 from "../../assets/images/图像 101@2x.png";
import icon100 from "../../assets/images/图像 100@2x.png";
import foodSecurityImg from "../../assets/images/组 898@2x.png";
import aiVideoInspectionImg from "../../assets/images/组 897@2x.png";
import foodSecurityDispatchImg from "../../assets/images/组 522@2x.png";
import foodSecuritySuperviseImg from "../../assets/images/组 896@2x.png";
import foodSecurityPromotionImg from "../../assets/images/组 895@2x.png";
import auditingBureauImg from "../../assets/images/审计局-首页@2x.png"
import phoneImg from "../../assets/images/手机@2x.png";
import z469Img from "../../assets/images/组 469@2x.png"
import icon1 from "../../assets/images/路径 1952@2x.png";
import icon2 from "../../assets/images/联合 15@2x.png";
import icon3 from "../../assets/images/路径 1848@2x.png";
import icon4 from "../../assets/images/路径 1711@2x.png";

const AuditLiterature = (props) => {
  const cellList = [{
    imgUrl:icon102,
    text:"审前方案聚焦"
  },{
    imgUrl:icon101,
    text:"审中过程聚焦"
  },{
    imgUrl:icon100,
    text:"审计报告聚焦"
  }];
  const list = [
    {
      title:"审前方案聚焦",
      titleEnglish:"Pre-trial program focus",
      content:"帮助审计人员建立部门职责、法规政策的审计策略聚焦，节约人员的审前调研时间及经历；减少审计资料的重复翻阅、重复查阅等作；通过信息化审前、帮手，为审计工作建立更有针对性的审计方案",
      child:()=>{
        return (
          <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:32}}>
            <img src={auditingBureauImg} alt="审计局" style={{width:"778px"}} />
          </div>
        )
      }
    },{
      notShow:true,
      child:()=>{
        return (
          <div className="item-list2" style={{display:"flex",alignItems:"center",justifyContent:"space-between",marginTop:32}}>
            <img src={phoneImg} alt="手机.png" />
            <div className="right">
              <div className="box" style={{background: "linear-gradient(to right,#FF5F6E 1px,#FF5F6E 20px,#FFFFFF 20px,#FFFFFF 100%)"}}>
                <div className="title">部门政策依据支撑</div>
                <div className="content">系统支持自定义收录本辖区各部门单位的规章制度法规政策依据，为我们审计人员提供审前、审中、审后的政策依据；提升审计人员对被审单位的政策了解，强化部门政策的落实，优化审计过程的审计质量及效率</div>
              </div>
              <div className="box" style={{background: "linear-gradient(to right,#FCAE60 1px,#FCAE60 20px,#FFFFFF 20px,#FFFFFF 100%)"}}>
                <div className="title">部门职责支撑</div>
                <div className="content">系统支持自主建立各部门的职责数据中心，帮助审计人员有效考量被审单位的职责落实情况，建立审计工作的职责定位聚焦，为审计报告落地提供部门职责依据的数据支撑</div>
              </div>
              <div className="box">
                <div className="title">有效性预警支撑</div>
                <div className="content">系统支持自定义设定法规政策效力预警功能，对超期的、即将超期的、可预见性超期的文献资料，建立周期预警功能措施，保障审计中所查政策文献的效力，为审计质量精准性提升，做好所查文献的有效性保障</div>
              </div>
            </div>
          </div>
        )
      }
    },{
      title:"审中过程聚焦",
      titleEnglish:"Audit report focus",
      content:"建立部门职责、法规政策的多维度检索应用，实现纵向、横向多角度资料搜寻，为审计人员建立更具有针对性的审计方案提供服务，帮助审计人员拓宽审计思维，强化审中策略优化审中政策精准查询，为审中定则提升查询效率",
      imgUrl:foodSecurityDispatchImg,
      child:()=>{
        return (
          <div className="item-list3" style={{display:"flex",alignItems:"center",marginTop:32}}>
            <div className="box">
              <div style={{background:"linear-gradient(to right,#14DBF8,#34C0F8)"}}>
              平台搭载多种查询算法，支持各类文献通过标题、内容、附件、及附件内容全方位的智能检索，为一线审计人员建立审中法规政策的文献查询支撑；结合信息化优势有效把控政策的执行者，全方位了解各政策二点指导方针及执行单位执行情况；为审计过程建立智能检索助手
              </div>
              <div>
                <div>综合政策检索支撑</div>
                <div>Comprehensive policy search support</div>
              </div>
            </div>
            <div className="box">
              <div style={{background:"linear-gradient(to right,#64EDEA,#64AAF2)",paddingTop:"65px"}}>
              打造文献级次划分，为审计工作人员提供文献检索定位；同时系统支持在不同文献级次下，聚焦不同行业分类；为审计资料查阅，实现政策文献级次、文献行业的交叉智能查询应用
              </div>
              <div>
                <div>文献级次检索支撑</div>
                <div>Literature level search support</div>
              </div>
            </div>
            <div className="box">
              <div style={{background:"linear-gradient(to right,#44AAF8,#B177FF)",paddingTop:"76px"}}>
              建立多种行业文献分类，构筑横向审计思维聚焦，为审计工作提供精准的行业文献划分，借助信息化工具拓宽审计思维，强化审中策略
              </div>
              <div>
                <div>行业类别检索支撑</div>
                <div>Industry category search support</div>
              </div>
            </div>
          </div>
        )
      }
    },{
      title:"审计报告聚焦",
      titleEnglish:"Food safety standardize production supervision and supervision platform",
      content:"平台建设涵盖常用性审计定性、处理依据、法规政策等多方位的参考文献，为审计人员的审计结论提供多 种判断策略,有效为审计人员输出高质量的审计报告提供帮助",
      
      child:()=>{
        return (
          <div className="item-list4" style={{display:"flex",alignItems:"center",justifyContent:"space-between",marginTop:32}}>
            <div className="left-bor">
              <div className="left">
                <img src={z469Img} alt="审计图标" />
                <div className="title">审计定性支撑</div>
                <div className="wire"></div>
                <div className="content">收录常用性审计定性规范，梳理典型审计案例的定性处理方式；为审计报告的出具，审后结论的规范及质量建立参考依据</div>
              </div>
            </div>
            <div className="right">
              <div className="box">
                <div className="title">
                  <img src={icon1} alt="" />
                  <div>整合审计资源</div>
                </div>
                <div className="content">完成审计工作中法规文献的资源整合，建立基础性和战略性的审计文献信息库，实现审计项目间的信息资源共享，提升一线审计人员的协同工作能力</div>
              </div>
              <div className="box">
                <div className="title">
                  <img src={icon1} alt="" />
                  <div>提升审计效率</div>
                  </div>
                <div className="content">缩短审前调研时间，通过文献检索平台辅助工作人员制定审计实施方案；优化审中政策精准查询；规范审计定性案例；让审计人员对定性依据方便、快查；充分提升审计工作中的综合查、综合用的效率</div>
              </div>
              <div className="box">
                <div className="title">
                  <img src={icon1} alt="" />
                  <div>强化审计规范</div>
                </div>
                <div className="content">通过系统建立多方位的法规依据、政策依据、审计定性依据、处理处罚依据、部门职责依据，同时对文献建立纵横双向查询应用，系统从审前设计、审中定责、审后定性全程配套规范文献跟辅，保障审计方案的规范引用</div>
              </div>
              <div className="box">
                <div className="title">
                  <img src={icon1} alt="" />
                  <div>提高审计质量</div>
                </div>
                <div className="content">打造审计资料共享机制，对符合质量的政策文件同意共享，从原来一人查资料一人用，到多人查资料多人用的方式；结合嵌入式审计与信息化共享审计相结合，建立高标准的文献法规库、审计定性库、审计处理方式库等应用模块，充分辅助审计人员审计依据的规范应用，全面提升审计报告的质量</div>
              </div>
            </div>
          </div>
        )
      }
    }
  ];
  return (
    <div className="audit-literature-page">
      <Header current="智慧审计文献服务平台" active={3} history={props.history} />
      <div className="bazaarImgCell">
        <img src={bigPicture} alt="智慧审计卫士" />
      </div>
      <div className="summarize">
        <div className="title">智慧审计卫士</div>
        <div className="english-title">Smart Audit Guard</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <div className="img-box">
                    <img src={_.imgUrl} alt={_.text} />
                  </div>
                  <div>{_.text}</div>
                </div>
              )
            })
          }
        </div>
        <div className="describe">智慧审计文献服务平台服务与审计单位，建立解決审前收集政策法规难的问题、解决审计中定责难的问题、解决审后定性依据不准确问题；通过系统 建立多维度的审计应用，打造审计资料共享共用机制，充分辅 助审计人员审计依据的规范应用，全面提高审计报告的质量</div>
        <img className="system-home-img" src={z57Img} alt="系统首页" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item}>
            {item.child?item.child():null}
          </Cell> 
        })
      }
    </div>
  )
}
export default AuditLiterature;