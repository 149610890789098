import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './smartVillage.css'
import p1 from './imgs/p1.mov'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'
import p11 from './imgs/p11.png'
import p12 from './imgs/p12.png'
import p13 from './imgs/p13.png'
import p14 from './imgs/p14.png'
import p15 from './imgs/p15.png'
import p16 from './imgs/p16.png'
import icon1 from './imgs/icon1.png'
import icon2 from './imgs/icon2.png'
import icon3 from './imgs/icon3.png'
import icon4 from './imgs/icon4.png'
import icon5 from './imgs/icon5.png'
import icon6 from './imgs/icon6.png'
import icon7 from './imgs/icon7.png'
import icon8 from './imgs/icon8.png'
import icon9 from './imgs/icon9.png'
import icon10 from './imgs/icon10.png'
import icon11 from './imgs/icon11.png'
import icon12 from './imgs/icon12.png'
import icon13 from './imgs/icon13.png'
import icon14 from './imgs/icon14.png'
import icon15 from './imgs/icon15.png'
import icon16 from './imgs/icon16.png'
import icon17 from './imgs/icon17.png'
import icon18 from './imgs/icon18.png'
import icon19 from './imgs/icon19.png'
import icon20 from './imgs/icon20.png'
import icon21 from './imgs/icon21.png'
import icon22 from './imgs/icon22.png'
import icon23 from './imgs/icon23.png'
import icon24 from './imgs/icon24.png'
import icon25 from './imgs/icon25.png'
import icon26 from './imgs/icon26.png'
import icon27 from './imgs/icon27.png'
import icon28 from './imgs/icon28.png'
import icon29 from './imgs/icon29.png'
import icon30 from './imgs/icon30.png'
import {baseUrl} from '../../../api/commonSrc'



function SmartVillage(props){

    // const borderBg1 = {
    //     width:"100%",
    //     height:'30rem',
    //     backgroundImage: 'url(' +p1 + ')',
    //     backgroundRepeat:"no-repeat",
    //     backgroundSize: "100% 100%",
    //     backgroundPosition:"center",
    //     margin: "0 auto",
    //     padding: "60px 160px",
    
    // };


    return(
        <div style={{width:'100%'}}>
            <Header current="智慧乡村" active={4}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div className="smartAudit-video-box">
                    <video className="smartAudit-video-background" src={p1} muted="muted" autoplay="autoplay" loop></video>
                    <img src={p7} className='smartVillage-box-img1'/>
                </div>
                <div className='foodSafety-box2' style={{height:800,marginTop:-50}}>
                    <div className='foodSafety-box3' style={{width:700}}>
                        <div className='foodSafety-box1-font2'>党建引领</div>
                        <div className='foodSafety-box1-font3'>Party building leads</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>加强基层组织建设，深入抓党建促乡村振兴，充分发挥基层党组织领导作用和党员先锋模范作用</div>
                        <div className='campusSecurity-box-left'>
                            <div className='riskHandling-box2-img1'>
                                <img src={p3} style={{height:57,width:57,marginTop:1}}/>
                                <div className='campusSecurity-box2-font1'>党支部视频巡查</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p4} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>党员提拔</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p5} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>党员网格化管理</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p9} style={{height:57,width:58,marginTop:1}}/>
                                <div className='campusSecurity-box2-font1'>党群服务</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={p8} style={{height:52,width:58,marginTop:3,marginBottom:3}}/>
                                <div className='campusSecurity-box2-font1'>三会一课台账</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/partyBuilding')}/>
                        </div>
                    </div>
                    <img src={p2} className='smartAudit-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:700,marginTop:-100,marginBottom:50}}>
                    <img src={p10} className='smartAudit-box3-img1'/>
                    <div className='foodSafety-box3' style={{width:600,marginLeft:100}}>
                        <div className='foodSafety-box1-font2'>政务便民</div>
                        <div className='foodSafety-box1-font3'>Government affairs are convenient for the people</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>实施数字乡村建设发展工程，推动“互联网+”服务向农村延伸覆盖，推进涉农事项在线办理</div>
                        <div className='campusSecurity-box-left'>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon1} style={{height:56,width:58,marginTop:2}}/>
                                <div className='campusSecurity-box2-font1'>便民服务站</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon2} style={{height:54,width:58,marginTop:2,marginBottom:2}}/>
                                <div className='campusSecurity-box2-font1'>乡村快报亭</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon3} style={{height:50,width:76,marginTop:4,marginBottom:4}}/>
                                <div className='campusSecurity-box2-font1'>公共服务窗口</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon4} style={{height:58,width:54}}/>
                                <div className='campusSecurity-box2-font1'>一张图</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/convenience')}/>
                        </div>
                    </div>
                    
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:700,marginTop:-100,marginBottom:50}}>
                    <div className='foodSafety-box3' style={{width:700}}>
                        <div className='foodSafety-box1-font2'>基层治理</div>
                        <div className='foodSafety-box1-font3'>Grassroots governance</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>以信息技术为支撑，以互通互联为基础，以整合资源为重点，以群众满意为标准。实现全区基层网格化管理服务的全区域、全定位、全管控和全参与。</div>
                        <div className='campusSecurity-box-left' style={{marginTop:60}}>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon5} style={{height:57,width:58,marginTop:1}}/>
                                <div className='campusSecurity-box2-font1'>考勤管理</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon6} style={{height:58,width:70}}/>
                                <div className='campusSecurity-box2-font1'>人房信息</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon7} style={{height:58,width:53}}/>
                                <div className='campusSecurity-box2-font1'>任务管理</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon8} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>民情收集</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon9} style={{height:58,width:48}}/>
                                <div className='campusSecurity-box2-font1'>视频连线</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/grassroots')}/>
                        </div>
                    </div>
                    <img src={p11} className='smartAudit-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:700,marginTop:-100,marginBottom:50}}>
                    <img src={p12} className='smartAudit-box3-img1'/>
                    <div className='foodSafety-box3' style={{width:600,marginLeft:100}}>
                        <div className='foodSafety-box1-font2'>数字三农</div>
                        <div className='foodSafety-box1-font3'>Digital three rural areas</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>全力抓好粮食生产和重要农产品供给强化现代农业基础支撑合理保障农民种粮收益，稳定全年粮食播种面积和产量</div>
                        <div className='campusSecurity-box-left'>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon10} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>数字农耕</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon11} style={{height:52,width:58,marginTop:3,marginBottom:3}}/>
                                <div className='campusSecurity-box2-font1'>数字养殖</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon12} style={{height:58,width:50}}/>
                                <div className='campusSecurity-box2-font1'>智慧农业大棚</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon13} style={{height:58,width:58,marginTop:1}}/>
                                <div className='campusSecurity-box2-font1'>物联设备设施</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/digitalFarming')}/>
                        </div>
                    </div>
                    
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:700,marginTop:-100,marginBottom:50}}>
                    <div className='foodSafety-box3' style={{width:700}}>
                        <div className='foodSafety-box1-font2'>产业融合</div>
                        <div className='foodSafety-box1-font3'>Industrial convergence</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>聚焦产业促进乡村发展，实施数字乡村建设发展工程,推进数字技术与农村生产生活深度融合，持续开展数字乡村试点</div>
                        <div className='campusSecurity-box-left'>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon14} style={{height:57,width:58,marginTop:1}}/>
                                <div className='campusSecurity-box2-font1'>产业融合</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon15} style={{height:40,width:58,marginBottom:9,marginTop:9}}/>
                                <div className='campusSecurity-box2-font1'>机械农耕</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon16} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>赋闲零工</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon17} style={{height:56,width:58,marginBottom:1,marginTop:1}}/>
                                <div className='campusSecurity-box2-font1'>耕地租赁</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon18} style={{height:50,width:58,marginBottom:4,marginTop:4}}/>
                                <div className='campusSecurity-box2-font1'>助农合作社</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon19} style={{height:54,width:58,marginBottom:2,marginTop:2}}/>
                                <div className='campusSecurity-box2-font1'>农资服务站</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/industryAmalgamation')}/>
                        </div>
                    </div>
                    <img src={p13} className='smartAudit-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:700,marginTop:-100,marginBottom:50}}>
                    <img src={p14} className='smartAudit-box3-img1'/>
                    <div className='foodSafety-box3' style={{width:600,marginLeft:100}}>
                        <div className='foodSafety-box1-font2'>企业安环</div>
                        <div className='foodSafety-box1-font3'>Enterprise security</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>以“安全生产，环境保护”为目标，按照“安全监管、安防监控、监测预警三位一体”的建设思路建立健全安监信息化工作评估标准和考核机制。</div>
                        <div className='campusSecurity-box-left' style={{marginTop:60}}>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon20} style={{height:57,width:58,marginTop:1}}/>
                                <div className='campusSecurity-box2-font1'>九小场所</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon21} style={{height:58,width:55}}/>
                                <div className='campusSecurity-box2-font1'>巡查巡检</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon22} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>自查自检</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon23} style={{height:57,width:58,marginTop:1}}/>
                                <div className='campusSecurity-box2-font1'>安全培训</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/safetyRing')}/>
                        </div>
                    </div>
                    
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:700,marginTop:-100,marginBottom:50}}>
                    <div className='foodSafety-box3' style={{width:700}}>
                        <div className='foodSafety-box1-font2'>民声民情</div>
                        <div className='foodSafety-box1-font3'>People's voice, public sentiment</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>投诉数据受理的统计分析应用平台，以及通过投诉事件建立有效的风险预警数字化模型，实现投诉风险预警，提升局方区域风险、事件风险、时效风险、时期风险的有效把控措施。</div>
                        <div className='campusSecurity-box-left' style={{marginTop:60}}>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon24} style={{height:58,width:51}}/>
                                <div className='campusSecurity-box2-font1'>事件办理</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon25} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>事件追踪统计</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon26} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>风险聚焦</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/peopleslivelihood')}/>
                        </div>
                    </div>
                    <img src={p15} className='smartAudit-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:700,marginTop:-100}}>
                    <img src={p16} className='smartAudit-box3-img1'/>
                    <div className='foodSafety-box3' style={{width:600,marginLeft:100}}>
                        <div className='foodSafety-box1-font2'>乡村生态</div>
                        <div className='foodSafety-box1-font3'>Rural ecology</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='campusSecurity-box3-font1'>创新农村精神文明建设有效平台载体推广积分制等治理方式，有效发挥村规民约、家庭家教家风作用</div>
                        <div className='campusSecurity-box-left' >
                            <div className='riskHandling-box2-img1'>
                                <img src={icon27} style={{height:52,width:58,marginTop:3,marginBottom:3}}/>
                                <div className='campusSecurity-box2-font1'>村务一键通</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon28} style={{height:58,width:52}}/>
                                <div className='campusSecurity-box2-font1'>积分治理</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon29} style={{height:58,width:51}}/>
                                <div className='campusSecurity-box2-font1'>重点场所</div>
                            </div>
                            <div className='riskHandling-box2-img1'>
                                <img src={icon30} style={{height:58,width:58}}/>
                                <div className='campusSecurity-box2-font1'>卫星遥感</div>
                            </div>
                        </div>
                        <div className='smartAudit-box-left1'>
                            <img src={p6} className='campusSecurity-box-img1' onClick={()=>window.location.replace(baseUrl + '/#/ruralEcology')}/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )

}

export default SmartVillage 