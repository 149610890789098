import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import bigImgs from "../../assets/images/民生4@2x.png"
import TaskDeliveryImg from "../../assets/images/驾驶舱7@2x.png";
import cellIcon1 from "../../assets/images/图像 170@2x.png";
import cellIcon2 from "../../assets/images/图像 171@2x.png";
import cellIcon3 from "../../assets/images/图像 172@2x.png";

import cellImg1 from "../../assets/images/事件办理中心@2x.png";
import cellImg2 from "../../assets/images/事件追踪统计@2x.png";
import cellImg3 from "../../assets/images/u1424@2x.png";


const Peopleslivelihood = (props) => {
  const cellList = [{
    imgUrl:cellIcon1,
    text:"事件办理"
  },{
    imgUrl:cellIcon2,
    text:"事件追踪统计"
  },{
    imgUrl:cellIcon3,
    text:"风险聚焦"
  }];
  const list = [
    {
      title:"事件办理",
      titleEnglish:"Event handling",
      content:"建立风险事件的受理分派管理，提升承办人员规范民声事件办理，实现局内民声受理、分派、审批、办结答复流程化管理体制，提升民声事件的答复率及答复规范。",
      imgUrl:cellImg1,
      reverse:false, // 排序，图片在y
      width:464,
      height:364
    },{
      title:"事件追踪统计",
      titleEnglish:"Event tracking statistics",
      content:"实现建立民声事件统计分析平台，实现民声环节统计、民声类型统计、民声来源统计、民声承办部门工作量等数据统计。支持根据所需数据表格导出",
      imgUrl:cellImg2,
      reverse:true, // 排序，图片在y
      width:464,
      height:364
    },{
      // blueTitle:"党群服务中心",
      title:"风险事件研判分析",
      titleEnglish:"Risk event research and analysis",
      content:"为局方建立数字化分析模型，实现对民声事件的分类、分析及风险研判，建立民声事件举报大数据分析平台，建立民声事件的区域风险、事件风险、时效风险及时期风险。",
      imgUrl:cellImg3,
      reverse:false, // 排序，图片在y
      width:693,
      height:316,
      rowHeight:578

    }
  ];
  return (
    <div className="peopleslive-lihood-page">
      <Header current="民声民情" active={4} history={props.history} />
      <div className="bazaarImgCell">
        <img src={bigImgs} alt="民声民情" />
      </div>
      <div className="summarize">
        <div className="title">民声民情</div>
        <div className="english-title">People's livelihood and people's feelings</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          <div></div>
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  <div>{_.text}</div>
                </div>
              )
            })
          }
          <div></div>
        </div>
        <div className="describe">投诉数据受理的统计分析应用平台，以及通过投诉事件建立有效的风险预警数字化模型，实现投诉风险预警，提升局方区域风险、事件风险、时效风险、时期风险的有效把控措施。</div>
        <img className="system-home-img" style={{width:766,height:600}} src={TaskDeliveryImg} alt="系统首页" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default Peopleslivelihood;