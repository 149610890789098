import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import largeImg from "../../assets/images/网格9@2x.png";
import TaskDeliveryImg from "../../assets/images/蒙版组 68@2x.png";
import cellIcon1 from "../../assets/images/图像 151@2x.png";
import cellIcon2 from "../../assets/images/图像 152@2x.png";
import cellIcon3 from "../../assets/images/图像 153@2x.png";
import cellIcon4 from "../../assets/images/图像 155@2x.png";
import cellIcon5 from "../../assets/images/图像 154@2x.png";

import cellImg1 from "../../assets/images/组 922@2x.png";
import cellImg2 from "../../assets/images/组 923@2x.png";
import cellImg3 from "../../assets/images/任务管理1@2x.png";
import cellImg4 from "../../assets/images/民情收集@2x.png";
import cellImg5 from "../../assets/images/驾驶舱3@2x.png";
import cellImg6 from "../../assets/images/519325D6-0AF6-87FD-915F-922DCCFE6E76@2x.png";


const Grassroots = (props) => {
  const cellList = [{
    imgUrl:cellIcon1,
    text:"考勤管理"
  },{
    imgUrl:cellIcon2,
    text:"人房信息"
  },{
    imgUrl:cellIcon3,
    text:"任务管理"
  },{
    imgUrl:cellIcon4,
    text:"民情收集"
  },{
    imgUrl:cellIcon5,
    text:"视频连线"
  }];
  const list = [
    {
      title:"考勤管理",
      titleEnglish:"Time and attendance management",
      content:"为网格员考勤提供智能化管理，精准定位，实时数据统计，方便合理的安排网格员的工作，准确的统计网格员的考勤情况，实现科学化的管理制度",
      imgUrl:cellImg1,
      reverse:false, // 排序，图片在y
    },{
      title:"人房信息",
      titleEnglish:"Room information",
      content:"为掌握人口、房屋、户籍、特殊人群底数，有效提高服务管理能力。进一步健全网格化管理体系，切实提升网格化管理服务水平，更方便、快捷的服务居民。以网格为单位，将网格内常住村民、流动村民的详细信息进行录入。做到信息录入全覆盖、不漏户、不漏人、不漏项，确保信息真实准确，及时有效。",
      imgUrl:cellImg2,
      reverse:true, // 排序，图片在y
    },{
      // blueTitle:"党群服务中心",
      title:"网格任务管理",
      titleEnglish:"Grid task management",
      content:"网格化管理、组团式服务”是坚持和谐发展、促进社会持续稳定的一个制度创新，是改善民生、加强基层政权建立的一个有效载体，是固本强基。",
      imgUrl:cellImg3,
      reverse:false, // 排序，图片在y
    },{
      // blueTitle:"企业场景",
      title:"民情收集",
      titleEnglish:"Public sentiment collection",
      content:"民情民意的收集是网格治理工作的重要组成部分，可以让政府更了解百姓的情况和诉求，进而更好的为百姓进行服务。",
      imgUrl:cellImg4,
      reverse:true, // 排序，图片在y
    },{
      // blueTitle:"党员提拔系统",
      title:"视频连线",
      titleEnglish:"Video connection",
      content:"应急视频通话功能，实现了远程连线、协同管理的实时性、高效率网格治理方式；当网格员在巡检过程中遇到突发情况时，网格管理者可通过该功能与现场的网格员进行视频通话，第一时间了解现场的情况，并通过视频实现对网格员工作的指导，让网格治理工作更精准、高效。",
      imgUrl:cellImg5,
      reverse:false, // 排序，图片在y
    }
  ];
  return (
    <div className="grassroots-page">
      <Header current="基层治理" active={4} history={props.history} />
      <div className="bazaarImgCell">
        <img src={largeImg} alt="基层治理" />
      </div>
      <div className="summarize">
        <div className="title">基层治理</div>
        <div className="english-title">Party building leads</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  <div>{_.text}</div>
                </div>
              )
            })
          }
        </div>
        <div className="describe">以信息技术为支撑，以互通互联为基础，以整合资源为重点，以群众满意为标准。实现全区基层网格化管理服务的全区域、全定位、全管控和全参与。以卫星图形式展示网格及网格信息，包括网格员信息、人员情况、土地情况、企业情况，可做到边界清晰、无重叠、无盲区、全覆盖。</div>
        <img className="system-home-img" src={TaskDeliveryImg} alt="系统首页" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default Grassroots;