import React,{Component} from 'react'
import "./app.css"
import Home from "./page/home/index"
import FoodSafety from "./page/smartMarket/foodSafety/index"
import DrugSafety from "./page/smartMarket/drugSafety/index"
import InformationSharing from "./page/smartMarket/informationSharing/index"
import RiskHandling from "./page/smartMarket/riskHandling/index"
import CampusSecurity from "./page/campusSecurity/home/index"
import SmartAudit from "./page/smartAudit/home/index"
import SmartVillage from "./page/smartVillage/home/index"
import Convenience from "./page/smartVillage/convenience/index"
import DigitalFarming from "./page/smartVillage/digitalFarming/index"
import SafetyRing from "./page/smartVillage/safetyRing/index"
import RuralEcology from "./page/smartVillage/ruralEcology/index"
import SmartMarket from "./page/smartMarket/index"; // 智慧市场
import TransparentPlant from "./page/transparentPlant/index"; // 透明工厂
import SpecialEquipment from "./page/specialEquipment/index"; // 特种设备
import CoordinateOfficeFlow from "./page/coordinateOfficeFlow/index";//协调办公
import CaseNumber from "./page/caseNumber/index";//案件案号
import FoodSafetyGuard from "./page/foodSafetyGuard";//校园食安
import AuditLiterature from "./page/auditLiterature";//智慧审计
import PartyBuilding from "./page/partyBuilding";//党建引领
import Grassroots from "./page/grassroots";//基层治理
import Peopleslivelihood from "./page/peopleslivelihood";//民生民情
import IndustryAmalgamation from "./page/industryAmalgamation"; // 产业融合
import AboutUs from "./page/aboutUs"; //关于我们
import EcoChain from './page/ecoChain'; //生态链
import {Switch, Route, Redirect, HashRouter} from "react-router-dom"
import ScrollToTop from './components/ScrollToTop/scrollToTop'
import Header from './components/header'
import Footer from './components/footer'
import {mobileUrl} from './api/commonSrc'
import { FullScreenContainer } from '@jiaminghi/data-view-react'
export default class App extends Component{

    componentDidMount() {
        window.addEventListener('resize', this.resizeListener);
        this.resizeListener();
        if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          //移动端
          window.location.href = mobileUrl;
        } 
    }
    componentWillMount() {
        window.removeEventListener('resize', this.resizeListener);
    }

    resizeListener() {
        // 定义设计图的尺寸 1920
        let designSize = 1920;
        // 获取 html 元素
        let html = document.documentElement;
        // 定义窗口的宽度
        let clientW = html.clientWidth;
        // html 的fontsize 大小
        let htmlRem = clientW * 15 / designSize;
        html.style.fontSize = htmlRem + 'px';
    }

    render(){
        return (
            // <FullScreenContainer className='app-full-screen'>
                <div
                    style={{
                        width:"100%",
                        height:"100%",
                    }}
                    className='globe-box'>
                    
                    <div>
                        <HashRouter>
                        <ScrollToTop>
                        <Switch >
                            <Route path='/home' component={Home} />
                            <Route path='/foodSafety' component={FoodSafety} />
                            <Route path='/drugSafety' component={DrugSafety} />
                            <Route path='/informationSharing' component={InformationSharing} />
                            <Route path='/riskHandling' component={RiskHandling} />
                            <Route path='/campusSecurity' component={CampusSecurity} />
                            <Route path='/smartAudit' component={SmartAudit} />
                            <Route path='/smartVillage' component={SmartVillage} />
                            <Route path='/convenience' component={Convenience} />
                            <Route path='/digitalFarming' component={DigitalFarming} />
                            <Route path='/safetyRing' component={SafetyRing} />
                            <Route path='/ruralEcology' component={RuralEcology} />
                            <Route path="/smartMarket" component={SmartMarket} />
                            <Route path="/transparentPlant" component={TransparentPlant} />
                            <Route path="/specialEquipment" component={SpecialEquipment}/>
                            <Route path="/coordinateOfficeFlow" component={CoordinateOfficeFlow} />
                            <Route path="/caseNumber" component={CaseNumber} />
                            <Route path="/foodSafetyGuard" component={FoodSafetyGuard} />
                            <Route path="/auditLiterature" component={AuditLiterature} />
                            <Route path="/partyBuilding" component={PartyBuilding} />
                            <Route path="/grassroots" component={Grassroots} />
                            <Route path="/peopleslivelihood" component={Peopleslivelihood} />
                            <Route path="/industryAmalgamation" component={IndustryAmalgamation} />
                            <Route path="/aboutUs" component={AboutUs} />
                            <Route path="/ecoChain" component={EcoChain} />
                            <Redirect to='/ecoChain'/>
                        </Switch>
                        </ScrollToTop>
                        </HashRouter>
                    </div>
                    <div className='footer'>
                        <Footer/>
                    </div>
                </div>

            // </FullScreenContainer>


        )

    }
}
