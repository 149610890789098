import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './ruralEcology.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'
import p11 from './imgs/p11.png'



function RuralEcology(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        margin: "0 auto",
        padding: "60px 160px",
    
    };


    return(
        <div style={{width:'100%'}}>
            <Header current="乡村生态" active={4}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                </div>
                <div className='foodSafety-box1'>
                    <div className='foodSafety-box1-font2'>乡村生态</div>
                    <div className='foodSafety-box1-font3'>Rural ecology</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p2} style={{height:80,width:88}}/>
                        <div className='foodSafety-box2-font1'>村务一键通</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p3} style={{height:80,width:71}}/>
                        <div className='foodSafety-box2-font1'>积分治理</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p4} style={{height:80,width:70}}/>
                        <div className='foodSafety-box2-font1'>重点场所</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p5} style={{height:80,width:80}}/>
                        <div className='foodSafety-box2-font1'>卫星遥感</div>
                    </div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}><div className='drugSafety-font1' style={{lineHeight:2}}>扎实开展重点领域农村技术设施建设，加快推动数字乡村标准化建设，深化乡村基础信息服务行动。创新农村精神文明建设有效平台载体推广积分制等治理方式，有效发挥村规民约、家庭家教家风作用</div>
                </div>
                <div className='foodSafety-box2' style={{height:350}}>
                    <img src={p6} style={{height:428,width:600}}/>
                </div>
                <div className='foodSafety-box1' style={{marginTop:100,marginBottom:60}}>
                    <div className='foodSafety-box1-font2'>产品中心</div>
                    <div className='foodSafety-box1-font3'>Product Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:1000,marginLeft:-130}}>
                        <div className='foodSafety-box1-font2'>村务一键通</div>
                        <div className='foodSafety-box1-font3'>Village affairs push-to-talk</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>群众可以在线上收到广播、村委最新通知、村委信息公示、建言献策、查看村民随手拍内容、并且关注助力墙，做到足不出户了解村中事。</div>
                    </div>
                    <img src={p7} className='safetyRing-box3-img1' style={{height:332,width:385}}/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p8} className='safetyRing-box3-img2' style={{height:332,width:390}}/>
                    <div className='foodSafety-box3' style={{paddingLeft:200,width:900}}>
                        <div className='foodSafety-box1-font2'>积分治理</div>
                        <div className='foodSafety-box1-font3'>Points governance</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>创新农村文明建设有效平台载体，推广积分制，有效发挥村规农约、家庭家教家风作用，充分发挥群众在乡村振兴的主体作用，动员和激励群众积极参与新时代文明实践活动。以社会主义核心价值观为引领，牢牢把握传播新思想、引领新风尚的工作目标，聚焦重点工作任务，大力推广乡村治理积分制管理工作，积极引导群众过上好日期、养成好习惯、形成好风气，以新时代文明实践焕发乡风文明新气象，夯实乡村振兴之路。</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:1000,marginLeft:-50}}>
                        <div className='foodSafety-box1-font2'>重点场所</div>
                        <div className='foodSafety-box1-font3'>Key places</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>基于VR技术构筑多元化宣传特色，引导鼓励农民开展庭院和村庄绿化美化，建设村庄小微公园和公共绿地。</div>
                    </div>
                    <img src={p9} className='safetyRing-box3-img3' style={{height:364,width:464}}/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p10} className='safetyRing-box3-img2' style={{height:356,width:583}}/>
                    <div className='foodSafety-box3' style={{paddingLeft:80,width:850}}>
                        <div className='foodSafety-box1-font2'>环卫收运</div>
                        <div className='foodSafety-box1-font3'>Sanitation harvesting</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>运用云计算、大数据、物联网、互联网、安防视频等相关技术，通过智能终端感知设备进行数据采集、挖掘、分析及处理，通过互联网或移动互联网，建设统一的管理信息共享平台实现对垃圾收运管理所涉及到的人、车、物、事件进行全过程实时监督，提升垃圾收运作业质量，降低运营成本。</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:960,marginLeft:-50}}>
                        <div className='foodSafety-box1-font2'>卫星遥感</div>
                        <div className='foodSafety-box1-font3'>Satellite remote sensing</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>结合“互联网+”、卫星遥感、AI图像识别等新一代信息技术手段，构建农田综合监测监管，实现农田建设项目管理、农田专题信息上图展示分析以及农田利用情况监督管理。以大数据、云计算、3S等为技术基底，以宅基地为基本管理单元，利用无人机开展巡航监测，针对乡村各种违规乱象进行综合监测及管理，推动乡村治理精准化，助力优化生产生活生态空间，持续改善村容村貌和人居环境，建设美丽宜居乡村，推动农村全面进步。</div>
                    </div>
                    <img src={p11} className='safetyRing-box3-img3' style={{height:416,width:510}}/>
                </div>
            </div>
        </div>
    )

}

export default RuralEcology 