import React from "react";

const Cell = ({item}) =>{
  console.log("aaa=>",item)
  return (
    <section style={{flexDirection:item.reverse?"row-reverse":"row"}}>
      <div className="content-item">
        <div className="item-title">
          <text className="blue-title">{item.blueTitle}</text>
          {item.title}
          </div>
        <div className="item-titleEnglish">{item.titleEnglish}</div>
        <div className="item-blue-piece"></div>
        <div className="item-content">{item.content}</div>
      </div>
      <div className="content-img">
        <img src={item.imgUrl} style={{width:item.width,height:item.height}} alt="" />
      </div>
    </section>
  )
}
export default Cell;