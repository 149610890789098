import React,{ useState, useEffect} from 'react'
// import './home.css'
import Header from '../../../components/header'
import './convenience.css'
import p1 from './imgs/p1.png'
import p2 from './imgs/p2.png'
import p3 from './imgs/p3.png'
import p4 from './imgs/p4.png'
import p5 from './imgs/p5.png'
import p6 from './imgs/p6.png'
import p7 from './imgs/p7.png'
import p8 from './imgs/p8.png'
import p9 from './imgs/p9.png'
import p10 from './imgs/p10.png'
import p11 from './imgs/p11.png'
import p12 from './imgs/p12.png'


function Convenience(props){

    const borderBg1 = {
        width:"100%",
        height:'30rem',
        backgroundImage: 'url(' +p1 + ')',
        backgroundRepeat:"no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition:"center",
        margin: "0 auto",
        padding: "60px 160px",
    
    };


    return(
        <div style={{width:'100%'}}>
            <Header current="政务服务" active={4}/>
            <div style={{marginTop:74,width:'100%',height:'100%'}}>
                <div style={borderBg1}>
                </div>
                <div className='foodSafety-box1'>
                    <div className='foodSafety-box1-font2'>政务便民</div>
                    <div className='foodSafety-box1-font3'>Government affairs are convenient for the people</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p2} style={{height:80,width:82}}/>
                        <div className='foodSafety-box2-font1'>便民服务站</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p3} style={{height:80,width:87}}/>
                        <div className='foodSafety-box2-font1'>乡村快报亭</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p4} style={{height:80,width:122}}/>
                        <div className='foodSafety-box2-font1'>公共服务窗口</div>
                    </div>
                    <div className='imformationSharing-box2-img1'>
                        <img src={p5} style={{height:80,width:75}}/>
                        <div className='foodSafety-box2-font1'>一张图</div>
                    </div>
                </div>
                <div className='foodSafety-box2' style={{height:130}}><div className='drugSafety-font1'>实施数字乡村建设发展工程，推动“互联网＋”服务向农村延伸覆盖，推进涉农事项在线办理。方便群众办事、知事。为群众提供便民服务、快报亭、综合服务一览、公共服务等。是与群众交流互动的对话框。</div>
                </div>
                <div className='foodSafety-box2' style={{height:350}}>
                    <img src={p6} style={{height:428,width:600}}/>
                </div>
                <div className='foodSafety-box1' style={{marginTop:100,marginBottom:60}}>
                    <div className='foodSafety-box1-font2'>产品中心</div>
                    <div className='foodSafety-box1-font3'>Product Center</div>
                    <div className='foodSafety-box1-font1'></div>
                </div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:1000,marginLeft:-100}}>
                        <div className='foodSafety-box1-font2'>便民服务站</div>
                        <div className='foodSafety-box1-font3'>Convenient service stations</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>打造便民服务窗口，实现便民事项查、办、帮办预约、在线咨询等；支持资料准备线上查，到场直接办理；针对帮办预约的事项，对预约人累计个人“乡村积分”</div>
                    </div>
                    <img src={p7} className='convenience-box3-img1'/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p8} className='imformationSharing-box3-img2' style={{height:367,width:320}}/>
                    <div className='foodSafety-box3' style={{paddingLeft:230,width:950}}>
                        <div className='foodSafety-box1-font2'>乡村快报亭</div>
                        <div className='foodSafety-box1-font3'>Village Express Kiosk</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>打造乡镇通知、告知、政策宣讲等信息，直达群众的信息共享平台群众“看”的多，积分“攒”的多。</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:900,marginLeft:-180}}>
                        <div className='foodSafety-box1-font2'>政府服务“一览表”</div>
                        <div className='foodSafety-box1-font3'>Government Services "List"</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>便民服务中心电话及职责<br/>扶贫办电话及职责<br/>农业农村局电话及职责<br/>住房保障中心电话及职责<br/>村委电话及职责<br/>各监管所单位电话及职责</div>
                    </div>
                    <img src={p9} className='imformationSharing-box3-img3' style={{height:362,width:400,marginLeft:-50}}/>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p10} className='imformationSharing-box3-img2' style={{height:440,width:480,marginTop:-100}}/>
                    <div className='foodSafety-box3' style={{paddingLeft:150,width:950}}>
                        <div className='foodSafety-box1-font2'>综合服务网点“一张图”</div>
                        <div className='foodSafety-box1-font3'>Integrated service outlets "one map"</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>以便民服务为目的，以掌上“一张图”为手段，做好便民服务网点公示工作,有效查询乡医服务点、便民商超、兽医服务站、物流运输公司、便民服务点</div>
                    </div>
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <div className='foodSafety-box3' style={{width:930}}>
                        <div className='foodSafety-box1-font2'>人才振兴赋能</div>
                        <div className='foodSafety-box1-font3'>Talent revitalization and empowerment</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>人才振兴赋能，从看到攒，从点开到学习，通过攒“积分”提升人才振兴服务，农家书屋，攻读教育，云听在线，技能学习多方面助力人才振兴</div>
                    </div>
                    <img src={p11} className='imformationSharing-box3-img3' style={{height:422,width:590,marginTop:-80}} />
                </div>
                <div className='foodSafety-box3-font2'></div>
                <div className='foodSafety-box2' style={{height:500,marginTop:0}}>
                    <img src={p12} className='imformationSharing-box3-img2' style={{height:332,width:478}}/>
                    <div className='foodSafety-box3' style={{paddingLeft:150,width:950}}>
                        <div className='foodSafety-box1-font2'>公共服务窗口</div>
                        <div className='foodSafety-box1-font3'>Public service window</div>
                        <div className='foodSafety-box1-font1' style={{marginLeft:0}}></div>
                        <div className='foodSafety-box3-font1'>展示乡镇工作事项，包含低收入人群、非遗文化保护、雪亮工程路况公示、四好农村路台账等</div>
                    </div>
                </div>
 
            </div>
        </div>
    )

}

export default Convenience 