/**
 * 党建引领
 */
import React from "react";
import Header from "../../components/header/index";
import Cell from "./cell";
import "./index.less";
import specialEquipmentImg from "../../assets/images/党建@2x.png"
import TaskDeliveryImg from "../../assets/images/驾驶舱1@2x.png";
import cellIcon1 from "../../assets/images/组 859@2x.png";
import cellIcon2 from "../../assets/images/图像 142@2x.png";
import cellIcon3 from "../../assets/images/图像 143@2x.png";
import cellIcon4 from "../../assets/images/图像 144@2x.png";
import cellIcon5 from "../../assets/images/图像 145@2x.png";
import cellIcon6 from "../../assets/images/图像 146@2x.png";

import cellImg1 from "../../assets/images/组 913@2x.png";
import cellImg2 from "../../assets/images/组 914@2x.png";
import cellImg3 from "../../assets/images/组 915@2x.png";
import cellImg4 from "../../assets/images/组 916@2x.png";
import cellImg5 from "../../assets/images/组 917@2x.png";
import cellImg6 from "../../assets/images/519325D6-0AF6-87FD-915F-922DCCFE6E76@2x.png";


const PartyBuilding = (props) => {
  const cellList = [{
    imgUrl:cellIcon1,
    text:"党支部视频巡查"
  },{
    imgUrl:cellIcon2,
    text:"党员提拔"
  },{
    imgUrl:cellIcon3,
    text:"党员网格化管理"
  },{
    imgUrl:cellIcon4,
    text:"党群服务"
  },{
    imgUrl:cellIcon5,
    text:"三会一课台账"
  },{
    imgUrl:cellIcon6,
    text:"党组织活动"
  }];
  const list = [
    {
      title:"党员网格化管理",
      titleEnglish:"Grid management of party members",
      content:"对党员的基本信息、学习信息、发展信息、活动信息等九项信息进行记录。基本信息、党内生活历程生成“一人一档”的党员画像，党员变化情况实时更新，一事一记、一步一审。",
      imgUrl:cellImg1,
      reverse:false, // 排序，图片在y
      width:609,
      height:364
    },{
      title:"三会一课台账",
      titleEnglish:"Three sessions and one lesson ledger",
      content:"对党员、党组织生活历程进行全方位动态跟踪，管理人员可提交活动计划，开始前自动提醒，参加人员通过二维码、定位等多种方式签到，会后提交会议心得、会议总结等，助力组织生活常态化。",
      imgUrl:cellImg2,
      reverse:true, // 排序，图片在y
      width:515,
      height:364
    },{
      // blueTitle:"党群服务中心",
      title:"党群服务中心",
      titleEnglish:"Party and mass service center",
      content:"通过对党群服务中心的分布统计、建筑面积、专职人员数量等数据统计分析，更合理的规划服务中心的建设和运营工作。",
      imgUrl:cellImg3,
      reverse:false, // 排序，图片在y
      width:548,
      height:364
    },{
      // blueTitle:"企业场景",
      title:"党组织活动中心",
      titleEnglish:"Center for the activity of party organizations",
      content:"在线创建活动，并把活动下发给相应的党员同志，党员可根据各自的实际情况进行积极报名参加，组织者通过对现场活动的照片和信息采集编辑成党组织活动展示内容，让更多的党员同志学习了解。",
      imgUrl:cellImg4,
      reverse:true, // 排序，图片在y
      width:495,
      height:382
    },{
      // blueTitle:"党员提拔系统",
      title:"党员提拔系统",
      titleEnglish:"One yard for multiple uses in public scenes",
      content:"基于系统为每台特种设备生成的一物一码，实现公众扫码后，显示本台设备的基础信息、特种设备使用单位信息、检验状态、风险问题提报等功能",
      imgUrl:cellImg5,
      reverse:false, // 排序，图片在y
      width:361,
      height:348
    },{
      // blueTitle:"党员提拔系统",
      title:"党支部视频巡查",
      titleEnglish:"Video patrol of party branches",
      content:"对党组织会议情况进行巡查，包含对党组织开会情况，党员参会情况，开会中的违规情况进行巡查取证。取证情况可发送给负责人，起到督导作用。",
      imgUrl:cellImg6,
      reverse:true, // 排序，图片在y
      width:642,
      height:268
    },
  ];
  return (
    <div className="party-building-page">
      <Header current="党建服务" active={4} history={props.history} />
      <div className="bazaarImgCell">
        <img src={specialEquipmentImg} alt="透明工厂" />
      </div>
      <div className="summarize">
        <div className="title">党建引领</div>
        <div className="english-title">Party building leads</div>
        <div className="item-blue-piece"></div>
        <div className="group">
          {
            cellList.map(_=>{
              return (
                <div className="cell">
                  <img src={_.imgUrl} alt={_.text} />
                  <div>{_.text}</div>
                </div>
              )
            })
          }
        </div>
        <div className="describe">整合党建工作事项，融合数字化党建引领工作赋能，打造2大平台+5大系统，实现网格党支部平台、党支部视频巡查督导平台及党组会议活动中心系统、党费缴纳系统、党员选举系统、三会一课系统、党群服务系统。夯实党支部党建引领工作的扎实推进。</div>
        <img className="system-home-img" src={TaskDeliveryImg} alt="系统首页" />
        <div className="title" style={{marginTop:"80px"}}>产品中心</div>
        <div className="english-title">Product Center</div>
        <div className="item-blue-piece"></div>
      </div>
      {
        list.map(item=>{
          return <Cell item={item} /> 
        })
      }
    </div>
  )
}
export default PartyBuilding;